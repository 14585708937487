import React from "react";
import "./Frame1321313960.sass";

function Frame1321313960(props) {
  const { rectangle4, rectangle2, className } = props;

  return (
    <div className={`frame-1321313960 border-14-7px-white ${className || ""}`}>
      <div className="overlap-group-80">
        <img className="rectangle-4-6" src={rectangle4} />
        <img className="rectangle-2-1" src={rectangle2} />
        <img className="rectangle-441-1" src="/img/rectangle-441-6@2x.png" />
        <div className="title-1 montserrat-medium-white-12-9px">
          <span className="spanghmgs montserrat-medium-white-12-9px">Translator</span>
        </div>
      </div>
    </div>
  );
}

export default Frame1321313960;
