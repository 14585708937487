import classNames from 'classnames';
import React from 'react';
import Card from '../Card';
import GenericScreen from './GenericScreen';
import { useMobileDetect } from 'react-mobile-detect'

const verticalOrders = {
    normal: 'normal',
    reversed: 'reversed'
}

const getCardMobileLayout = (gridSize, unitIndex) => {
    return {'gridRow': Math.abs(unitIndex - gridSize)}
}

export const vOrders = verticalOrders;

const MulticardScreen = (props) => {
    const {
        className,
        vOrder,
        cards,
        ...rest
    } = props;
    const {isMobile} = useMobileDetect();

    const isFirstCardAnIllustration = cards.length === 2 
        && (cards[0].background || cards[0].attachment ) 
        && (!cards[0].title && !cards[0].subtitle && !cards[0].text)
    const shouldInvertOnMobile = (vOrder === verticalOrders.reversed && isMobile ) || isFirstCardAnIllustration
    const isTwoPanel = cards.length === 2

    return <GenericScreen {...{...rest, style: {...rest.style, '--stack-size': cards.length || 1 }}} twoPanel={isTwoPanel} className={classNames(className, 'multicard', vOrder === verticalOrders.reversed && 'reversed-mobile-order') }>
        {
            cards.map((cardProps, i) => {
                const cardMobileStyles = shouldInvertOnMobile ? getCardMobileLayout(cards.length, i) : {}
                const elementProps = {...cardProps, style: {...cardProps.style, ...cardMobileStyles, '--stack-size': cards.length || 1}}
                return <Card key={`card_${i}`} {...elementProps }/>
            }) 
        }
    </GenericScreen>
}

MulticardScreen.defaultProps = {
    vOrder: verticalOrders.normal,
    cards: []
}

export default MulticardScreen