import classNames from 'classnames';
import React from 'react';
import './Image.sass'

const Image = (props) => {
    const {
        src,
        className
    } = props;
    return <div
            className={classNames('image-component', className)}
            style={{'backgroundImage': `url(${src})`}}
        />
}

export default Image