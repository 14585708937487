import React from 'react';
import Helmet from 'react-helmet'
import Logo from '../../components/Logo';
import { useLocation } from 'react-router-dom';
import NavMenu from '../../components/HeaderComponents/NavMenu';
import Button from '../../components/Button';

import './HeaderContainer.sass'
import { MobileView } from 'react-mobile-detect';
import BrowserView from 'react-mobile-detect/dist/components/BrowserView';
import Burger from '../../components/Burger';
import BurgerButton from '../../components/BurgerButton';
import classNames from 'classnames';
import { purchasePaths } from '../../const/paths';
import LinkWithRegionPicker from '../../components/LinkWithRegionPicker';

const HeaderSection = (props) => {
    const { children, className } = props;
    return <div className={classNames('container-header-section', className)}>
        {children}
    </div>
}

const HeaderContainer = () => {
    const { pathname } = useLocation();
    return <>
    <Helmet>
        <title>Teleportivity</title>
    </Helmet>
    <div
        className='container-header'
    >
    <Logo/>
    <MobileView>
        <BurgerButton/>
    </MobileView>
    <BrowserView>
        <NavMenu currentPath={pathname}/>
        <HeaderSection>
            <LinkWithRegionPicker
                hrefs={purchasePaths.login}
            >
            <Button
                title="login"
            />
            </LinkWithRegionPicker>
            <LinkWithRegionPicker
                hrefs={purchasePaths.signup}
            >
            <Button
                title="get started"
                color="blue"
            />
            </LinkWithRegionPicker>
        </HeaderSection>
    </BrowserView>
    </div>
    </>
}

export default HeaderContainer