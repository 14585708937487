import React from 'react';

const button1Data = {
    className: "",
};

const frame13213140621Data = {
    buttonProps: button1Data,
};

const frame1321313936Data = {
    frame1321314061: "/img/fill-24-18@2x.png",
    frame1321314062Props: frame13213140621Data,
};

const frame13213138981Data = {
    spanText1: "Video Concierge",
    spanText2: "No more staff shortages. No more unattended front desks. Just unparalleled service from anywhere to anywhere.",
};

const frame10913432Data = {
    rectangle4: "/img/rectangle-4-2@1x.png",
    className: "frame-1091343-1",
};

const frame13213138982Data = {
    spanText1: <>Help <br />As-A-Service</>,
    spanText2: "Whatever your requirements, support your staff and customers across thousands of access points with ease.",
    className: "frame-1321313898-4",
};

export const homePageData = {
    homePage: "/img/home-page@1x.png",
    spanText1: <>Connection,<br />reimagined.</>,
    spanText2: "Teleportivity creates connection solutions for the modern age of communication, blending the efficiency and scale of digital with the power of the personal touch.",
    qrCodeLanding: "/img/qr-code-landing-1@2x.png",
    spanText3: "SCAN to talk to us live! Or click",
    spanText4: "here",
    spanText5: "Discover Teleportivity >",
    frame13213139301: "/img/frame-1321313930-1-1@2x.png",
    spanText6: "Intercoms",
    spanText7: "Redefine what your intercom is capable of with a new level of accessibility, functionality and ease.",
    spanText8: "More >",
    frame13599781: "/img/group-1359886-1@1x.png",
    spanText9: "More >",
    spanText10: "More >",
    frame13599782: "/img/frame-1359978-1@2x.png",
    spanText11: "Reimagine your connection today",
    spanText12: "Get started with us, when ready, contact us to put you in touch with one of our techology partners.",
    spanText13: "Find Authorised Dealer",
    spanText14: "Contact Teleportivity",
    frame1321313946: "/img/frame-1321313946-2@2x.png",
    frame1321313947: "/img/frame-1321313947-2@2x.png",
    frame1321313948: "/img/frame-1321313948-2@2x.png",
    frame1321313949: "/img/frame-1321313949-2@2x.png",
    frame1321313950: "/img/frame-1321313950-2@2x.png",
    frame1321313951: "/img/frame-1321313951-2@2x.png",
    frame1321313936Props: frame1321313936Data,
    frame13213138981Props: frame13213138981Data,
    frame1091343Props: frame10913432Data,
    frame13213138982Props: frame13213138982Data,
};