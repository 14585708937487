import React from 'react';

const button7Data = {
    className: "",
};

const frame13213140626Data = {
    className: "frame-1321314062-5",
    buttonProps: button7Data,
};

const frame132131393623Data = {
    className: "frame-1321313936-2",
    frame1321314062Props: frame13213140626Data,
};

const hardware2Data = {
    className: "hardware-3",
};

const frame13213139783Data = {
    screenshot20220225At16461: "/img/screenshot-2022-02-25-at-16-51-1-1@2x.png",
    className: "frame-1321313978-1",
};

export const x2Page5Data = {
    x2Page_5: "/img/2-page-5-1@2x.png",
    image91: "/img/image-91-2@2x.png",
    google_MapsLogo1: "/img/google-maps-logo-1-1@2x.png",
    image92: "/img/image-92-1@2x.png",
    image93: "/img/image-93-1@2x.png",
    googleforms_LogoE15299213911531: "/img/googleforms-logo-e1529921391153-1-1@2x.png",
    adobe_Acrobat_Dc_Logo_20201: "/img/adobe-acrobat-dc-logo-2020-1-1@2x.png",
    spanText1: "Adobe PDF",
    servicenow_Logo1: "/img/servicenow-logo-1-1@2x.png",
    x1_Qaxizthcika5Bzumkcy2Q1: "/img/1--qaxizthcika5bzumkcy2q-1-1@2x.png",
    x6117A82537Bc3B7A02Bd5472_60A2746E0: "/img/6117a82537bc3b7a02bd5472-60a2746e0721c10af774eb79-microsoft-20fo-1@2x.png",
    shadows: "/img/shadows-7@2x.png",
    frame: "/img/frame-4@2x.png",
    intercomMobileDoorOnly: "/img/intercom---mobile---door-only-4@1x.png",
    screenShine: "/img/screen-shine-2@2x.png",
    spanText2: "Third party? Zero Problem",
    spanText3: "Designed to effortlessly connect with popular  products you use every day. You can now, quite literally, open the door and welcome people into a world of integrated access functionality.",
    spanText4: "List of Integrated partners >",
    frame13213139362Props: frame132131393623Data,
    hardwareProps: hardware2Data,
    frame1321313978Props: frame13213139783Data,
};