import React from "react";
import Frame13598842 from "../Frame13598842";
import Frame13213140622 from "../Frame13213140622";
import "./Frame13213139363.sass";

function Frame13213139363(props) {
  const { className, frame13213140622Props } = props;

  return (
    <div className={`frame-1321313936-6 ${className || ""}`}>
      <img className="frame-1321314061-9" src="/img/fill-24-18@2x.png" />
      <Frame13598842 />
      <Frame13213140622 className={frame13213140622Props.className} />
    </div>
  );
}

export default Frame13213139363;
