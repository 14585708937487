import React from 'react';
import { mobileOnlyClass } from '../../../const/styling';
import Button from '../../Button';
import Card from '../../Card'
import MulticardScreen from '../../ContentScreens/MulticardScreen';
import { homePageData } from '../values';
import './FindDealerBlock.sass';

const FindDealerBlock = (props) => {
    return <MulticardScreen
        className={'find-dealers'}
        cards = {[
            {
                bgColor: 'none',
                title: 'Reimagine your connection today',
                subtitle: <>
                    <span>Get started with us, when ready, contact us to put you in touch with one of our techology partners.</span>
                    <span className={mobileOnlyClass}><a>Case Studies</a> / <a> Use Cases</a></span>
                    </>,
                className: 'column-card',
                children: <div className='block-actions'>
                <Button
                    title={'Find Authorised Dealer'}
                    href={'/contact'}
                    />
                <Button
                    title={'Contact Teleportivity'}
                    color='blue'
                    href={'/contact'}
                 />
                </div>
            }
        ]}
    />
}

const oldfFindDealerBlock = (props) => {
    const { 
        spanText11, 
        spanText12, 
        spanText13, 
        spanText14
    } = homePageData;
    return <Card
        className='dealer-block contentitem'
        noTitle
    >
            <div className='block-description'>
                <div className="title">{spanText11}</div>
                <div className="description">{spanText12}</div>
            </div>
            <div className='block-actions'>
                <Button
                    title={spanText13}
                />
                <Button
                    title={spanText14}
                    color='blue'
                />
            </div>
    </Card>
}

export default FindDealerBlock;