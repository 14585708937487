import React from 'react';
import { qrideoPaths } from '../../const/paths';
import { desktopOnlyClass } from '../../const/styling';
import QRCodeLink from '../QRcodeLink';

const SplashScreenCaptionCardValues = {
    bgColor: 'none',
    title: `Connection,\nreimagined.`,
    subtitle: 'Teleportivity creates connection solutions for the modern age of communication, blending the efficiency and scale of digital with the power of the personal touch.',
    text: <div className={desktopOnlyClass} style={{'display': 'flex', 'align-items': 'center'}}>
            <QRCodeLink href={qrideoPaths.contact}/>
            <div style={{'padding-left': '1rem'}}>SCAN to talk to us live! Or click <a href={qrideoPaths.contact} target="_blank">here</a></div>
        </div>,
    callToAction: {
        label: 'Discover Teleportivity >',
        link: '#discover'
    },
    className: 'homepage-splash'
}

const SplashScreenImageCardValues = {
    background: '/img/connection-reimagined.png',
    bgColor: 'none',
}

const SplashScreenBlock = [
    SplashScreenCaptionCardValues,
    SplashScreenImageCardValues
]

export default { SplashScreenBlock }