import React from 'react';

const CrossIcon = (props) => {
    const { width, height, onClick, className } = props;
    return (
        <svg width={width} height={height} onClick={onClick} className={className} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.67391 8.99991L0.836914 15.8369L2.16291 17.1629L8.99991 10.3259L15.8369 17.1629L17.1629 15.8369L10.3259 8.99991L17.1629 2.16291L15.8369 0.836914L8.99991 7.67391L2.16291 0.836914L0.836914 2.16291L7.67391 8.99991Z" fill="currentColor"/>
        </svg>
    )
    
}

CrossIcon.defaultProps = {
    width: 18,
    height: 18,
    onClick: () => {}
}
export default CrossIcon