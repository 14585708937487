import classNames from 'classnames';
import React from 'react';
import Card from '../Card';
import Image from '../Image';

import './StaffCard'

const StaffCard = (props) => {
    const {
        name,
        job,
        location,
        description
    } = props;
    const imageName = name.toLowerCase().split(' ').join('-')
    const imagePath = ['img/', 'staff/', imageName, '.png'].join('')
   return <Card
            title={name}
            className={classNames(description && 'extendable', 'staff-card')}
            subtitle={
                <span className='job'>
                {
                    job.join 
                        ? job.join(' & ')
                        : job
                }
            </span>
            }
            text={<>
            <span className='location'>
                {
                    location.join
                        ? location.join(' / ')
                        : location
                }
            </span>
            {
                description && <span className='description'>
                    {description}
                </span>
            }
        </>}
        attachment={
            <Image src={imagePath} className='userpic'/>
        }
    
/>
}

export default StaffCard 