import classNames from 'classnames';
import React from 'react';
import { desktopOnlyClass, mobileOnlyClass } from '../../const/styling';
import Image from '../Image';
import GenericScreen from './GenericScreen';

const exclusions = {
    desktop: desktopOnlyClass,
    mobile: mobileOnlyClass
}

const OverlayImage = (props) => {
    const {
        src,
        showBgOn
    } = props;
    return <Image className={classNames('splash-backdrop', showBgOn && exclusions[showBgOn])} src={src}/>
}

const SplashScreen = (props) => {
    const { picture, className, showBgOn, ...rest } = props;
    return <GenericScreen
            className = {classNames('screen-splash', className)}
            {...rest}
        >
                <OverlayImage src={picture} showBgOn={showBgOn}/>
            </GenericScreen>

}

export default SplashScreen;