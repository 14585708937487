import React from 'react';



const button3Data = {
    className: "",
};

const frame13213140623Data = {
    className: "frame-1321314062-2",
    buttonProps: button3Data,
};

const frame13213139781Data = {
    screenshot20220225At16461: "/img/screenshot-2022-02-25-at-16-46-1-1@2x.png",
};

export const x2Page4Data = {
    x2Page_4: "/img/2-page-4@1x.png",
    overlapGroup2: "/img/stock-photo-digital-door-lock-security-systems-for-access-protec-1@2x.png",
    frame1321314061: "/img/fill-24-18@2x.png",
    spanText1: "Intercoms",
    spanText2: "Video Concierge",
    spanText3: "Help As A Service",
    spanText4: "Contact Us",
    spanText5: "Every door, floor and store at your fingertips",
    spanText6: "Whoever said you can only be in one place at a time, obviously hadn’t used QR Video Intercom. The cloud-based communication system gives you complete control over every point of every facility, so you can manage it all with ease.",
    overlapGroup: "/img/group-1359911-1@2x.png",
    frame1321313903: "/img/frame-1321313903-1@2x.png",
    frame1321314062Props: frame13213140623Data,
    frame1321313978Props: frame13213139781Data,
};