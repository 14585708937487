import React from "react";
import "./Frame1321313959.sass";

function Frame1321313959(props) {
  const { className } = props;

  return (
    <div className={`frame-1321313959 border-14-7px-white ${className || ""}`}>
      <div className="overlap-group-82">
        <img className="rectangle-441-3" src="/img/rectangle-441-5@2x.png" />
        <div className="title-3 montserrat-medium-white-12-9px">
          <span className="span2lp0g montserrat-medium-white-12-9px">Concierge</span>
        </div>
      </div>
    </div>
  );
}

export default Frame1321313959;
