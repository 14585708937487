import classNames from 'classnames';
import React from 'react';
import Card from '../Card';
import Screen from '../Screen';
import AdaptiveBlock from '../TwoColBlock';
import './GenericScreen.sass'

const ContentCard = (props) => {
    const { 
        background,
        content,
        title,
        subtitle,
        className,
        opacity = 1,
        blurBg
    } = props;
    const normalizedOpacity = Math.round(Math.max(0, Math.min(opacity * 255, 255)));
    const hexOpacity = normalizedOpacity.toString(16).padStart(2, '0')
    return <Card
        className={classNames('fullsize', className)}
        title={title}
        background={background}
        style={{
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backdropFilter: blurBg ? `blur(${blurBg}px)` : 'none'
        }}
    >
        {
            subtitle && <div className='contentcard-subtitle'>{subtitle}</div>
        }
        {
            content && <div className='contentcard-content'>{content}</div> 
        }
    </Card>
}

const GenericScreen = (props) => {
    const { 
        firstCard,
        secondCard,
        overlay,
        startOverlayFrom,
        screenTitle,
        screenBackground
     } = props
    return (
    <div
        className='content-screen '
        style={ screenBackground ? {
            backgroundImage: `url(${screenBackground})`,
            backgroundSize: 'cover',
            backgroundPositionX:'center',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat' 
        } : {}}
        >
            {
                screenTitle && <div className='screen-title'>{screenTitle}</div>
            }
            <AdaptiveBlock
                className={'genericscreen'}
                columns={2}
            >
                {firstCard && <ContentCard {...firstCard}/>}
                {secondCard && <ContentCard {...secondCard}/>}
            </AdaptiveBlock>
        { overlay && <div className={classNames('screen-overlay', startOverlayFrom && `${startOverlayFrom}-root`)}>
                        {overlay}
                    </div>
        }
    </div>)
}

export default GenericScreen;