import React from 'react'

const group13598382Data = {
    className: "group-1359838-1",
};

const button24Data = {
    className: "",
};

const frame132131406211Data = {
    className: "frame-1321314062-10",
    buttonProps: button24Data,
};

const frame132131393644Data = {
    className: "frame-1321313936-11",
    frame1321314062Props: frame132131406211Data,
};

const frame13213138971Data = {
    group1359843: "/img/group-1359843-3@2x.png",
    group1359787: "/img/group-1359787-6@2x.png",
};

const frame13213139601Data = {
    rectangle4: "/img/rectangle-4-7@2x.png",
    rectangle2: "/img/rectangle-2-2@2x.png",
};

const group13599021Data = {
    overlapGroup: "/img/rectangle-3-5@2x.png",
};

export const x5Page1Data = {
    x5Page_1: "/img/5-page-1-1@2x.png",
    spanText1: <>The intercom<br />Reimagined</>,
    spanText2: "Help-as-a-service",
    spanText3: <>Making small work of large scale operations. <br />The way we communicate and deploy staff in today’s hybrid workplace is evolving.</>,
    spanText4: "Take it to the next level.",
    overlapGroup: "/img/rectangle-3-6@2x.png",
    rectangle441: "/img/rectangle-441-7@2x.png",
    spanText5: "AV",
    spanText6: <>International Patent Application No.<br />PCT/AU2020/050089</>,
    group1359838Props: group13598382Data,
    frame13213139364Props: frame132131393644Data,
    frame1321313897Props: frame13213138971Data,
    frame1321313960Props: frame13213139601Data,
    group1359902Props: group13599021Data,
};


const button18Data = {
    className: "",
};

const frame13213140629Data = {
    className: "frame-1321314062-8",
    buttonProps: button18Data,
};

const frame132131393642Data = {
    frame1321314062Props: frame13213140629Data,
};

export const x5Page2Data = {
    x5Page_2: "/img/5-page-2-1@2x.png",
    spanText1: "Add the personal touch to every touchpoint",
    spanText2: "Dealing with a customer requirements across a multitude of different access points is a challenge. But service at scale no longer comes with a drop off in efficiency or personal support. Teleportivity’s “remote” as-a-service model means that, no matter what your requirements, we can support your staff and customers across hundreds and even thousands of access points with ease.",
    group1359910: "/img/rectangle-3-2@2x.png",
    spanText3: "Security Guard",
    group1359909: "/img/rectangle-3-3@2x.png",
    spanText4: "AV Engineer",
    group1359908: "/img/rectangle-2-1@2x.png",
    spanText5: "Translator",
    group1359839: "/img/group-1359839-4@2x.png",
    shadows: "/img/shadows-14@2x.png",
    frame: "/img/frame-12@2x.png",
    deviceBezelsIphonexsSpaceGray: "/img/device-bezels---iphonexs---space-gray-1@2x.png",
    screenShine1: "/img/screen-shine-13@2x.png",
    screenShine2: "/img/screen-shine-14@2x.png",
    notch: "/img/notch-shape-12@2x.png",
    camera: "/img/camera-5@2x.png",
    frame13213139364Props: frame132131393642Data,
};


const button26Data = {
    className: "",
};

const frame132131406212Data = {
    className: "frame-1321314062-11",
    buttonProps: button26Data,
};

const frame132131393645Data = {
    frame1321314062Props: frame132131406212Data,
};

export const x5Page3Data = {
    frame13213139364Props: frame132131393645Data,
};


const button19Data = {
    className: "",
};

const frame132131406210Data = {
    className: "frame-1321314062-9",
    buttonProps: button19Data,
};

const frame132131393643Data = {
    frame1321314062Props: frame132131406210Data,
};

const akarIconssearch2Data = {
    src: "/img/group-60@2x.png",
    className: "bilock-fill",
};

export const x5Page4Data = {
    x5Page_4: "/img/5-page-4-1@2x.png",
    spanText1: "Your customers will thank you. Face to face.",
    spanText2: "For a customer, there’s nothing as frustrating as being treated like a number – endlessly shuffled around an inefficient system that makes you have to repeat the same information over and over.  But with our Help-As-A-Service, customers simply need to scan a Teleportivity QR code on their device, and they can choose from a range of options to help them solve their issues, first time, every time.",
    x13671: "/img/1367-1-1@2x.png",
    qr: "/img/qr-3@2x.png",
    frame: "/img/frame-13@2x.png",
    overlapGroup4: "/img/intercom---mobile---door-only-8@1x.png",
    frame30: "/img/frame-30-1@2x.png",
    spanText3: "Instruction",
    oval2: "/img/oval-30@2x.png",
    oval3: "/img/oval-29@2x.png",
    oval4: "/img/oval-30@2x.png",
    call: "/img/oval-31@2x.png",
    combinedShape: "/img/combined-shape-8@2x.png",
    spanText4: "9:41",
    cellularConnection: "/img/cellular-connection-8@2x.png",
    wifi: "/img/wifi-10@2x.png",
    battery: "/img/battery-10@2x.png",
    spanText5: "teleportivity.com",
    spanText6: "A",
    spanText7: "A",
    spanText8: "Room Instructions",
    spanText9: <>How to use the safe<br /></>,
    spanText10: <>1. Push the code number you registered.<br />2. Pull the door to open the safe.<br />If you push the wrong numbers for 3 times, you’ll see “Error” message. The safe won’t work for 20 minutes. <br /></>,
    spanText11: "Telephone",
    rectangle: "/img/rectangle-6@2x.png",
    poweredByT: "/img/powered-by-t-1@2x.png",
    screenShine: "/img/screen-shine-15@2x.png",
    notch: "/img/notch-shape-13@2x.png",
    camera: "/img/camera-13@2x.png",
    frame13213139364Props: frame132131393643Data,
    akarIconssearchProps: akarIconssearch2Data,
};

const button16Data = {
    className: "",
};

const frame13213140628Data = {
    className: "frame-1321314062-7",
    buttonProps: button16Data,
};

const frame132131393641Data = {
    frame1321314062Props: frame13213140628Data,
};

export const x5Page5Data = {
    x5Page_5: "/img/5-page-5-1@2x.png",
    group1359843: "/img/group-1359843-2@2x.png",
    rectangle: "/img/rectangle-8@2x.png",
    ellipse88: "/img/ellipse-88-3@2x.png",
    spanText1: "Help-as-a-service",
    spanText2: <>Create your own “as-a-service” or “managed service” offering<br />Use YOUR staff, or our global ‘white label’ staffed options.</>,
    spanText3: "AV Live",
    frame13213139851: "/img/frame-1321313985-1@2x.png",
    group13597871: "/img/group-1359787-5@2x.png",
    spanText4: "Security Live",
    frame13213139852: "/img/frame-1321313985-2@2x.png",
    group13597872: "/img/group-1359787-5@2x.png",
    spanText5: "Concierge Live",
    frame13213139853: "/img/frame-1321313985-3@2x.png",
    group13597873: "/img/group-1359787-5@2x.png",
    overlapGroup: "/img/rectangle-3-1@2x.png",
    spanText6: <>Your own <br />“As-a-service” <br />Offering</>,
    frame13213139364Props: frame132131393641Data,
};

const group13598383Data = {
    className: "group-1359838-2",
};

const button27Data = {
    className: "",
};

const frame132131406213Data = {
    className: "frame-1321314062-12",
    buttonProps: button27Data,
};

const frame13213138972Data = {
    group1359843: "/img/group-1359843-4@2x.png",
    group1359787: "/img/group-1359787-7@2x.png",
    className: "frame-1321313897-3",
};

const frame13213139592Data = {
    className: "frame-1321313959-1",
};

const frame13213139602Data = {
    rectangle4: "/img/rectangle-4-10@2x.png",
    rectangle2: "/img/rectangle-2-3@2x.png",
    className: "frame-1321313960-1",
};

const group13599022Data = {
    overlapGroup: "/img/rectangle-3-7@2x.png",
    className: "group-1359902-2",
};

export const x5Page6Data = {
    x5Page_6: "/img/5-page-6-1@2x.png",
    spanText1: <>The intercom<br />Reimagined</>,
    spanText2: "As-A-Service at your service",
    spanText3: "Whether you’re in security or sales; whether you oversee facilities or product support; whether you deal in AV or CX, our As-A-Service model can help you operate at maximum scale and efficiency. Forget backlogs and bottlenecks – Teleportivity means one set of hands can support one hundred customers with ease.",
    frame1321314061: "/img/fill-24-18@2x.png",
    spanText4: "Intercoms",
    spanText5: "Video Concierge",
    spanText6: "Help As A Service",
    spanText7: "Contact Us",
    overlapGroup: "/img/rectangle-3-6@2x.png",
    rectangle441: "/img/rectangle-441-7@2x.png",
    spanText8: "AV",
    group1359838Props: group13598383Data,
    frame1321314062Props: frame132131406213Data,
    frame1321313897Props: frame13213138972Data,
    frame1321313959Props: frame13213139592Data,
    frame1321313960Props: frame13213139602Data,
    group1359902Props: group13599022Data,
};




const button28Data = {
    className: "",
};

const frame132131406214Data = {
    className: "frame-1321314062-13",
    buttonProps: button28Data,
};

export const x5Page7Data = {
    x5Page_7: "/img/5-page-7-1@2x.png",
    frame1359972: "/img/frame-1359972-1@2x.png",
    spanText1: "Help-Desk AV/IT Support reimagined",
    spanText2: "with Live Video Help-Desk Support",
    spanText3: "No matter how many rooms, spaces or sites you need to cover, our Live Help Desk makes small work of large scale support.",
    qr: "/img/qr-4@1x.png",
    frame1359971: "/img/frame-1359971-1@2x.png",
    frame1321314061: "/img/frame-1321314061-28@2x.png",
    spanText4: "Intercoms",
    spanText5: "Video Concierge",
    spanText6: "Help As A Service",
    spanText7: "Contact Us",
    frame1321314062Props: frame132131406214Data,
};



const footer4Data = {
    className: "footer-4",
};

const x2PageFooter3Data = {
    footerProps: footer4Data,
};

const frame13598843Data = {
    className: "frame-1359884-1",
};

const button31Data = {
    className: "",
};

const frame132131406215Data = {
    className: "frame-1321314062-14",
    buttonProps: button31Data,
};

const frame13213139113Data = {
    className: "frame-1321313911-2",
};

const frame132131391311Data = {
    group: "/img/group-46@2x.png",
};

const frame13213139173Data = {
    group1: "/img/group-65@2x.png",
    group2: "/img/group-69@2x.png",
    className: "frame-1321313917-2",
    frame1321313913Props: frame132131391311Data,
};

const frame132131391312Data = {
    group: "/img/group-67@2x.png",
};

const frame13213139149Data = {
    group: "/img/group-50@2x.png",
};

const frame132131391529Data = {
    group: "/img/group-69@2x.png",
};

const frame13213139156Data = {
    className: "frame-1321313917-4",
    frame1321313913Props: frame132131391312Data,
    frame1321313914Props: frame13213139149Data,
    frame13213139152Props: frame132131391529Data,
};

export const x5Page8Data = {
    x5Page_8: "/img/5-page-8-1@2x.png",
    frame1321314061: "/img/fill-24-18@2x.png",
    spanText1: "Get Started Free",
    spanText2: "My QR Fonecom",
    spanText3: "Register now",
    spanText4: "Help-as-a-Service",
    spanText5: "Buy Now",
    spanText6: "Try for 30 Days",
    frame1359884Props: frame13598843Data,
    frame1321314062Props: frame132131406215Data,
    frame1321313911Props: frame13213139113Data,
    frame1321313917Props: frame13213139173Data,
    frame1321313915Props: frame13213139156Data,
};
