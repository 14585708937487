import React from "react";
import "./RectangleButton2.sass";

function RectangleButton2(props) {
  const { rectangleButton, className } = props;

  return (
    <div className={`rectangle-button-6 ${className || ""}`} style={{ backgroundImage: `url(${rectangleButton})` }}>
      <div className="number valign-text-middle roboto-bold-rose-3-7px">
        <span>
          <span className="span4ykdz roboto-bold-rose-3-7px">911</span>
        </span>
      </div>
    </div>
  );
}

export default RectangleButton2;
