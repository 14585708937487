import React from "react";
import { useMobileDetect } from 'react-mobile-detect';
import Image from "../../Image";
import './FeatureCarousel'

const getFeatureimage = (id) => `/img/prev${id+1}.png`;
const features = [
    'Navigation Panel',
    'Link to Google Maps & Google Directions',
    'Optional QR Lock',
    [
        'Pre-recorded videos',
        'Youtube / MP4'
    ],
    'Documents & Images',
    'Intercom system',
    'Multiple User Calling Groups',
    'Call Queuing System',
    'Unlock the door from the app',
    'Unlock the door right from the call',
    [
        'Video call',
        'Flip Camera / Transfer',
        'Call / Augmented Reality'
    ],
]

const FeatureElement = (props) => {
    const { image, feature, id } = props;
 
    return <div className="item" style={{'--item-order': id}}>
        <div className="title">
            {
            feature.join 
                ? feature.map(feat => <span key={feat}>{feat}</span>)
                : <span>{feature}</span>
            }
            <div className="spacer"/>
        </div>
        <Image src={image} className='picwrapper'/>
    </div>
}

const FeatureCarousel = (props) => {
    const {isMobile} = useMobileDetect()
    const featureArray = isMobile ? [...features].reverse() :  features 
    
    return <div
        className="feature-carousel"
        style={{'--stack-length': features.length}}
        >
            {
                featureArray.map((feature, id) => {
                    const pictureId = isMobile ? featureArray.length - id - 1 : id
                    return <FeatureElement
                    key={getFeatureimage(pictureId)}
                    image={getFeatureimage(pictureId)}
                    feature={feature}
                    id={pictureId}
                />
                })
            }
        </div>
}

export default FeatureCarousel