import React from "react";
import "./SystemBarWhite.sass";

function SystemBarWhite() {
  return (
    <div className="system-bar-white">
      <div className="time sfprotext-semi-bold-white-12-3px">
        <span className="sfprotext-semi-bold-white-12-3px">9:41</span>
      </div>
      <img className="cellular-connection" src="/img/cellular-connection-2@2x.png" />
      <img className="wifi" src="/img/wifi-2@2x.png" />
      <img className="battery" src="/img/battery-2@2x.png" />
    </div>
  );
}

export default SystemBarWhite;
