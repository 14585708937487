import React from "react";
import Frame1359884 from "../Frame1359884";
import Frame1321314062 from "../Frame1321314062";
import "./Frame1321313936.sass";

function Frame1321313936(props) {
  const { frame1321314061, frame1321314062Props } = props;

  return (
    <div className="frame-1321313936">
      <img className="frame-1321314061" src={frame1321314061} />
      <Frame1359884 />
      <Frame1321314062 buttonProps={frame1321314062Props.buttonProps} />
    </div>
  );
}

export default Frame1321313936;
