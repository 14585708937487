import React from "react";
import "./X2Page9.sass";
import { x2Page9Data } from "./values";
import Screen from "../../Screen";
import Card from "../../Card";
import { planOptionNames, plans } from "../../../const/plans";
import { Link } from "react-router-dom";
import MulticardScreen from "../../ContentScreens/MulticardScreen";
import classNames from "classnames";
import Image from "../../Image";
import LinkWithRegionPicker from "../../LinkWithRegionPicker";
import { purchasePaths } from "../../../const/paths";

const serviceIcons = {
  text: '/img/group-46@2x.png',
  voice: '/img/group-50@2x.png',
  video: '/img/group-51@2x.png',
  access: '/img/group-30@2x.png'
}

const ServiceCard = (props) => {
  const {
    name,
    free,
    ...rest
  } = props

  const availableOptions = Object.entries(rest)
    .filter(([_, isActive]) => !!isActive)
  return <Card
    flexSize
    title={name}
  >
    <div className="option-list">
      {
        availableOptions.map(([option, scope]) => 
        <div key={option} className="option">
          <div className="option-icon">
            <img src={serviceIcons[option]}/>
          </div>
          <span className="option-description">{planOptionNames[option]}
          {
            Number.isInteger(scope)
            ? ` - ${scope} Calls`
            : ''
          }</span>
        </div>
          )
      }
    </div>
    <div className="option-ctas">
            {
              !free && <a href="/">
                <span>
                  Buy now
                  </span>
              </a>
            }
            <a href='/'>
              <span>
                {
                  free
                  ? 'Register now'
                  : 'Try for 30 days'

                }
              </span>
            </a>
          </div>
  </Card>
} 

const ServiceList = (props) => {
  return <div className="service-list" style={{'--item-count': Object.keys(plans).length}}>
    {
      Object.entries(plans).map(([plan, options]) => <ServiceCard key={plan} {...options}/>)
    }
  </div>
}

const GetFeatureSet = (options, isFree) => {
  const availableOptions = Object.entries(options)
  .filter(([_, isActive]) => !!isActive)
  return <>
  {
  availableOptions.map(([option, scope]) => 
  <div key={option} className="option">
      <Image src={serviceIcons[option]}/>
    <div className="option-description">{planOptionNames[option] || option}
    {
      Number.isInteger(scope)
      ? ` - ${scope} Calls`
      : ''
    }</div>
  </div>
    )
  }
      <div className="purchase-links">
            {
              !isFree && <LinkWithRegionPicker
                hrefs={purchasePaths.signup}
              >
              <a>
                <span>
                  Buy now
                  </span>
              </a>
              </LinkWithRegionPicker>
            }
            <LinkWithRegionPicker
              hrefs={purchasePaths.signup}
            >
            <a>
              <span>
                {
                  isFree
                  ? 'Register now'
                  : 'Try for 30 days'

                }
              </span>
            </a>
            </LinkWithRegionPicker>
          </div>
  </>
}

const GetPlanCards = (plansToParse) => {
  return Object.entries(plansToParse).map(([plan, options]) => {
    const { name: title, free, ...rest } = options
    const className = classNames('plan-option')

    const attachment = GetFeatureSet(rest, free)
    return { title, attachment, className }
  })
}

function X2Page9(props) {
  const { plans: customPlans } = props;
  return (<MulticardScreen
      title='Start free with QR Video Intercom'
      screenBackground='/img/purchasebg.png'
      cards={GetPlanCards(customPlans || plans)}
    
    />
  );
}

export default X2Page9;
