import React from "react";
import Frame13213139362 from "../../Frame13213139362";
import SystemBarWhite from "../../SystemBarWhite";
import Hardware from "../../Hardware";
import Frame1321313978 from "../../Frame1321313978";
import "./X2Page5.sass";
import { x2Page5Data } from "./values";
import Screen from "../../Screen";
import AdaptiveBlock from "../../TwoColBlock";

const image = '/img/thirdparty.png'

function X2Page5(props) {
  const {
    x2Page_5,
    image91,
    google_MapsLogo1,
    image92,
    image93,
    googleforms_LogoE15299213911531,
    adobe_Acrobat_Dc_Logo_20201,
    spanText1,
    servicenow_Logo1,
    x1_Qaxizthcika5Bzumkcy2Q1,
    x6117A82537Bc3B7A02Bd5472_60A2746E0,
    shadows,
    frame,
    intercomMobileDoorOnly,
    screenShine,
    spanText2,
    spanText3,
    spanText4,
    frame13213139362Props,
    hardwareProps,
    frame1321313978Props,
  } = x2Page5Data;

  return (
    <div className="content-screen page-sized">
      <Screen>
        <AdaptiveBlock
          column={2}
          verticalOrder={'rl'}
        >
          <div className="frame-1359971">
            <div className="frame-1359971-1">
              <img className="image-91" src={image91} />
              <img className="google_-maps-logo-1" src={google_MapsLogo1} />
              <img className="image-92" src={image92} />
              <img className="image-93" src={image93} />
              <img className="google-forms_logo-e1529921391153-1" src={googleforms_LogoE15299213911531} />
              <div className="group-1359872">
                <img className="adobe_-acrobat_dc_logo_2020-1" src={adobe_Acrobat_Dc_Logo_20201} />
                <div className="adobe-pdf roboto-medium-steel-gray-12-5px">
                  <span className="roboto-medium-steel-gray-12-5px">{spanText1}</span>
                </div>
              </div>
              <img className="service-now_logo-1" src={servicenow_Logo1} />
              <img className="x1_-qa-xi-zt-hci-ka5-b-zu-mkc-y2-q-1" src={x1_Qaxizthcika5Bzumkcy2Q1} />
              <img className="x6117a82537bc3b7a02bd" src={x6117A82537Bc3B7A02Bd5472_60A2746E0} />
            </div>
            <div className="phone-image">
              {/* <img src={image}/> */}
            </div>
          </div>
          <div className="frame-1321314079">
            <div className="frame-1321313896-2">
              <div className="third-party-zero-problem montserrat-bold-steel-gray-36px">
                <span className="montserrat-bold-steel-gray-36px">{spanText2}</span>
              </div>
              <div className="designed-to-effortle montserrat-normal-regent-gray-18px">
                <span className="montserrat-normal-regent-gray-18px">{spanText3}</span>
              </div>
              <div className="list-of-integrated-partners montserrat-semi-bold-royal-blue-18px">
                <span className="montserrat-semi-bold-royal-blue-18px">{spanText4}</span>
              </div>
            </div>
            <Frame1321313978
              screenshot20220225At16461={frame1321313978Props.screenshot20220225At16461}
              className={frame1321313978Props.className}
            />
          </div>
        </AdaptiveBlock>
        </Screen>
    </div>
  );
}

export default X2Page5;
