import React from 'react';
import { cardBackgroundColors } from '../../Card';
import MulticardScreen from '../../ContentScreens/MulticardScreen';
import Image from '../../Image';
import { homePageData } from '../values';
import './PartnerBlock.sass';

// const     frame1321313946: "/img/frame-1321313946-2@2x.png",
// frame1321313947: "/img/frame-1321313947-2@2x.png",
// frame1321313948: "/img/frame-1321313948-2@2x.png",
// frame1321313949: "/img/frame-1321313949-2@2x.png",
// frame1321313950: "/img/frame-1321313950-2@2x.png",
// frame1321313951: "/img/frame-1321313951-2@2x.png",
// frame1321313936Props: frame1321313936Data,

const PartnerBlock = (props) => {
    const { 
        frame1321313946,
        frame1321313947,
        frame1321313948,
        frame1321313949,
        frame1321313950,
        frame1321313951
     } = homePageData; 
    return <MulticardScreen
    className={'partner-block'}
     cards={[
         {
             bgColor: cardBackgroundColors.none,
             attachment: <Image src={"/img/frame-1321313946-2@2x.png"}/>
         },
         {
            bgColor: cardBackgroundColors.none,
            attachment: <Image src={"/img/frame-1321313947-2@2x.png"}/>
        },
        {
            bgColor: cardBackgroundColors.none,
            attachment: <Image src={"/img/frame-1321313948-2@2x.png"}/>
        },
        {
            bgColor: cardBackgroundColors.none,
            attachment: <Image src={"/img/frame-1321313949-2@2x.png"}/>
        },
        {
            bgColor: cardBackgroundColors.none,
            attachment: <Image src={"/img/frame-1321313950-2@2x.png"}/>
        },
        {
            bgColor: cardBackgroundColors.none,
            attachment: <Image src={"/img/frame-1321313951-2@2x.png"}/>
        }
     ]}
    />
    

}

export default PartnerBlock
{/* 
<img className="frame-1321313946" src={frame1321313946} />
        <img className="frame-1321313947" src={frame1321313947} />
        <img className="frame-13213139-2" src={frame1321313948} />
        <img className="frame-1321313949-1" src={frame1321313949} />
        <img className="frame-1321313950" src={frame1321313950} />
        <img className="frame-13213139-2" src={frame1321313951} />
    </MulticardScreen> */}