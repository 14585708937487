import React from 'react';


const button14Data = {
    className: "",
};

const frame13213140627Data = {
    className: "frame-1321314062-6",
    buttonProps: button14Data,
};

const frame132131393624Data = {
    className: "frame-1321313936-3",
    frame1321314062Props: frame13213140627Data,
};

const frame13213139131Data = {
    group: "/img/group-46@2x.png",
};

const frame13213139171Data = {
    group1: "/img/group-50@2x.png",
    group2: "/img/group-51@2x.png",
    frame1321313913Props: frame13213139131Data,
};

const frame13213139132Data = {
    group: "/img/group-43@2x.png",
};

const frame13213139141Data = {
    group: "/img/group-50@2x.png",
};

const frame132131391521Data = {
    group: "/img/group-51@2x.png",
};

const frame13213139151Data = {
    frame1321313913Props: frame13213139132Data,
    frame1321313914Props: frame13213139141Data,
    frame13213139152Props: frame132131391521Data,
};

const frame13213140151Data = {
    spanText: <>Fonecom <br />Home</>,
    frame1321313915Props: frame13213139151Data,
};

const frame13213139133Data = {
    group: "/img/group-46@2x.png",
};

const frame13213139142Data = {
    group: "/img/group-50@2x.png",
};

const frame132131391522Data = {
    group: "/img/group-51@2x.png",
};

const frame13213139153Data = {
    frame1321313913Props: frame13213139133Data,
    frame1321313914Props: frame13213139142Data,
    frame13213139152Props: frame132131391522Data,
};

const frame13213140152Data = {
    spanText: <>Fonceom <br />Office</>,
    className: "frame-1321314016",
    frame1321313915Props: frame13213139153Data,
};

const frame13213139134Data = {
    group: "/img/group-46@2x.png",
};

const frame13213139143Data = {
    group: "/img/group-50@2x.png",
};

const frame132131391523Data = {
    group: "/img/group-51@2x.png",
    className: "",
};

const frame13213139161Data = {
    group: "/img/group-30@2x.png",
    frame1321313913Props: frame13213139134Data,
    frame1321313914Props: frame13213139143Data,
    frame13213139152Props: frame132131391523Data,
};

const frame13213139135Data = {
    group: "/img/group-46@2x.png",
};

const frame13213139144Data = {
    group: "/img/group-50@2x.png",
};

const frame132131391524Data = {
    group: "/img/group-51@2x.png",
    className: "",
};

const frame13213139162Data = {
    group: "/img/group-30@2x.png",
    frame1321313913Props: frame13213139135Data,
    frame1321313914Props: frame13213139144Data,
    frame13213139152Props: frame132131391524Data,
};

export const x2Page9Data = {
    x2Page_9: "/img/2-page-9-1@2x.png",
    spanText1: "Start free with QR Video Intercom",
    spanText2: <>My QR <br />Fonecom</>,
    spanText3: "Register now",
    spanText4: "Buy Now",
    spanText5: "Try for 30 Days",
    spanText6: "Buy Now",
    spanText7: "Try for 30 Days",
    spanText8: <>Fonecom <br />Enterprise</>,
    spanText9: "Buy Now",
    spanText10: "Try for 30 Days",
    spanText11: "Fonecom Apartment",
    spanText12: "Buy Now",
    spanText13: "Try for 30 Days",
    frame13213139362Props: frame132131393624Data,
    frame1321313917Props: frame13213139171Data,
    frame13213140151Props: frame13213140151Data,
    frame13213140152Props: frame13213140152Data,
    frame13213139161Props: frame13213139161Data,
    frame13213139162Props: frame13213139162Data,
};