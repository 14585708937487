import React from "react";
import "./MobileMenu.sass";

function MobileMenu(props) {
  const { fill24, ciHamburger, className } = props;

  return (
    <div className={`mobile-menu ${className || ""}`}>
      <img className="fill-24" src={fill24} />
      <img className="cihamburger" src={ciHamburger} />
    </div>
  );
}

export default MobileMenu;
