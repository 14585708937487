import React from "react";
import "./X3Page7.sass";
import MulticardScreen from "../../ContentScreens/MulticardScreen";
import Image from "../../Image";
import { cardBackgroundColors } from "../../Card";

const teamServices = {
  fonecom: 'Fonecom Screen',
  haas: 'Help-as-a-service',
  concierge: 'Video Concierge',
  qrlock: 'Fonecom QR'
}

const iconPrefix = `img/teamservice_`

function X3Page7(props) {

  return (
        <MulticardScreen
          title='Meet the ultimate team player'
          className={'collaboration-features'}
          text={<div>
          Teleportivity solutions working in harmony offers an unparalleled level
          <br/>
          support and access across every facility, floor, and entryway.
          </div>}
            cards={
                [
                    {
                        title: 'Web, mobile or tablet device',
                        attachment: <Image src='img/qrideo_webdevice.png'/>
                    },
                    {
                      className: 'teamservice-list',
                      bgColor: cardBackgroundColors.none,
                      attachment: <>
                        {Object.entries(teamServices).map(([svc, label]) =>
                        <div className="service" key={svc}>
                          <Image src={`${iconPrefix}${svc}.png`}/>
                          <span>
                            {label}
                          </span>
                        </div>)}
                      </> 
                    },
                    {
                        title: 'Teleportivity Cloud',
                        attachment: <Image src='img/teleportivity-cloud.png'/>
                    }

                ]
            }
        />
  );
}

export default X3Page7;
