import React from 'react';
import { qrideoPaths } from '../../../const/paths';
import Button from '../../Button';
import QRCodeLink from '../../QRcodeLink';
import './ContactSection.sass'

const ContactSection = (props) => {
    const {className} = props;
    return (
    <>
    <div className="component-contact-section screen">
            <div className='buttonblock'>
                <Button
                    image={<QRCodeLink className={'test'} href={'http://qrideo.me/contact/us'}/>}
                    color={'gray'}
                    className={'component-contact-section-qrcode'}
                    titleClassName={'component-contact-section-qrcode-title'}
                    title={<>
                        SCAN to talk to us live!
                        Or click <a href={qrideoPaths.contact} target="_blank">here</a>
                    </>}
                />
                <span><a href={qrideoPaths.contact}>{qrideoPaths.contact}</a></span>
                <span><a href="mailto:contactus@teleportivity.com">contactus@teleportivity.com</a></span>
            </div>
            <div className='patents-and-policy'>
                <div className='patents'>
                    USA Patent 10,873,723
                    <br />
                    USA Patent 11,089,263
                </div>
                <div className='policy'>
                    <a href={qrideoPaths.privacy}>Privacy Policy</a>
                    <br/>
                    <a href={qrideoPaths.terms}>Terms and Conditions</a>
                </div>
            </div>
        <div className="right-section">
            <div>
                Website by: Inventrix Technologies Pty Ltd <br />© Teleportivity Inc. 2022
                <br />
                US Office: 1390 Market Street, San Francisco, CA, 94102, USA
                <br />
                AU Office: Suite 201-202, 413-417 New South Head Rd, Double Bay NSW 2028 AUSTRALIA
            </div>
    </div>
  </div>
  </>
  )
}

export default ContactSection