import getViewportSize from "./getViewportSize";

const scrollOptions = { behavior: 'smooth', block: 'start'}

const getHeaderOffset = () => {
    const header = document.querySelector('.container-header')
    const { height } = header.getBoundingClientRect();
    return height;
}

export const scrollToId = (id) => {
    const anchorTarget = document.querySelector(id);
    const { scrollX, scrollY } = window;
    if (anchorTarget) {
        const { top, left } = anchorTarget.getBoundingClientRect();
        const neededOffset = getHeaderOffset();
        window.scrollTo({left, top: top + scrollY - neededOffset, ...scrollOptions})
    }
}

const clickListener = (e) => {
    e.preventDefault()
    const { target } = e;
    const linkHref = target.closest('[href]').getAttribute('href')
    scrollToId(linkHref)
}

const interceptAnchorScroll = () => {
    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    anchorLinks.forEach((link) => {
        link.addEventListener('click', clickListener)
    })
    return () => {
        anchorLinks.forEach((link) => {
            link.removeEventListener(clickListener)
        })
    }
}

export default interceptAnchorScroll;
