import React from "react";
import Frame13213139363 from "../../Frame13213139363";
import RectangleButton5 from "../../RectangleButton5";
import { x3Page1Data } from "./values";
import "./X3Page1.sass";

function X3Page1(props) {
  const {
    spanText1,
    spanText2,
    overlapGroup2,
    frontIntercom1,
    unsplash6Zfvl4Yuam,
    spanText3,
    spanText4,
    combinedShape,
    spanText5,
    group,
    unsplashT2SaiAqipi,
    spanText6,
    spanText7,
    spanText8,
    rectangleButton1,
    iconConcierge,
    rectangleButton2,
    spanText9,
    union,
    subtract,
    frame13213139363Props,
    rectangleButton51Props,
    rectangleButton52Props,
    rectangleButton53Props,
    rectangleButton54Props,
  } = x3Page1Data;

  return (
    <div className="container-center-horizontal">
      <div className="x3-page1 screen">
        <div className="frame-1321314091">
          <div className="frame-1321313942">
            <div className="fonecom-outdoor-display montserrat-bold-steel-gray-72px">
              <span className="montserrat-bold-steel-gray-72px">{spanText1}</span>
            </div>
            <div className="make-an-unforgettabl-2 montserrat-normal-regent-gray-18px">
              <span className="montserrat-normal-regent-gray-18px">{spanText2}</span>
            </div>
          </div>
          <div className="overlap-group2-3" style={{ backgroundImage: `url(${overlapGroup2})` }}>
            <img className="front-intercom-1-1" src={frontIntercom1} />
            <div className="slide-169-14-2">
              <div className="overlap-group1-7">
                <img className="unsplash-6z-fvl4-yua-m-2" src={unsplash6Zfvl4Yuam} />
                <div className="group-1359695-2">
                  <div className="frame-1359702-2">
                    <div className="address-2 valign-text-middle roboto-normal-white-7-7px">
                      <span>
                        <span className="roboto-normal-white-7-7px">{spanText3}</span>
                      </span>
                    </div>
                    <div className="welcome-to-our-building-2 valign-text-middle roboto-medium-white-15-3px">
                      <span>
                        <span className="roboto-medium-white-15-3px">{spanText4}</span>
                      </span>
                    </div>
                  </div>
                  <div className="circle-button-2">
                    <div className="overlap-group-10">
                      <div className="oval-3-2"></div>
                      <img className="combined-shape-2" src={combinedShape} />
                    </div>
                  </div>
                </div>
                <div className="group-1359696-2">
                  <div className="flex-col-6">
                    <div className="search-2 valign-text-middle roboto-normal-white-8-3px">
                      <span>
                        <span className="roboto-normal-white-8-3px">{spanText5}</span>
                      </span>
                    </div>
                    <div className="rectangle-352-2"></div>
                  </div>
                  <div className="akar-iconssearch-2">
                    <img className="group-3" src={group} />
                  </div>
                </div>
                <div className="intercom-info-plaque-2">
                  <img className="unsplasht2-sai-aq-ip-i-2" src={unsplashT2SaiAqipi} />
                  <div className="group-1359704-2">
                    <div className="company-name-2 valign-text-middle roboto-medium-white-7-7px">
                      <span>
                        <span className="roboto-medium-white-7-7px">{spanText6}</span>
                      </span>
                    </div>
                    <p className="new-york-ny-4715-5-avenue-2 valign-text-middle roboto-normal-french-gray-6-7px">
                      <span>
                        <span className="roboto-normal-french-gray-6-7px">{spanText7}</span>
                      </span>
                    </p>
                  </div>
                  <div className="phone-2 roboto-normal-french-gray-6-7px">
                    <span className="roboto-normal-french-gray-6-7px">{spanText8}</span>
                  </div>
                </div>
                <div className="group-1359703-2">
                  <RectangleButton5
                    rectangleButton={rectangleButton51Props.rectangleButton}
                    fontistoKey={rectangleButton51Props.fontistoKey}
                  />
                  <div className="rectangle-button-14" style={{ backgroundImage: `url(${rectangleButton1})` }}>
                    <img className="icon-concierge-2" src={iconConcierge} />
                  </div>
                  <RectangleButton5
                    fontistoKey={rectangleButton52Props.fontistoKey}
                    rectangleButton={rectangleButton52Props.rectangleButton}
                    className={rectangleButton52Props.className}
                  />
                  <RectangleButton5
                    fontistoKey={rectangleButton53Props.fontistoKey}
                    rectangleButton={rectangleButton53Props.rectangleButton}
                    className={rectangleButton53Props.className}
                  />
                  <RectangleButton5
                    fontistoKey={rectangleButton54Props.fontistoKey}
                    rectangleButton={rectangleButton54Props.rectangleButton}
                    className={rectangleButton54Props.className}
                  />
                  <div className="rectangle-button-15" style={{ backgroundImage: `url(${rectangleButton2})` }}>
                    <div className="number-2 valign-text-middle roboto-bold-rose-6-7px">
                      <span>
                        <span className="roboto-bold-rose-6-7px">{spanText9}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="logo-1-color-2">
                  <img className="union-2" src={union} />
                  <img className="subtract-2" src={subtract} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default X3Page1;
