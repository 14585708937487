import classNames from "classnames";
import React from "react";

import './Screens.sass'

const GenericScreen = (props) => {
    const { 
        children,
        id,

        className,
        style,
        twoPanel,
        screenBackground,

        title,
        subtitle,
        text,
        overlay,

        fullwidth
    } = props;

    const hasDirectContent = title || subtitle || text;

    const composedStyle = {...style}
    if (screenBackground) {
        composedStyle.backgroundImage = `url(${screenBackground})`
        composedStyle.backgroundSize = 'cover'
        composedStyle.backgroundRepeat = 'no-repeat'
    }
    return <div
        className={
            classNames('content-screen', className, fullwidth && 'full-width', screenBackground && 'with-background')
        }
        id={id}
        style={ composedStyle}
    >
        {  hasDirectContent && <div className={'fullscreen-content'}>
                {title && <h1 className="title">{title}</h1>}
                {subtitle && <h3 className="subtitle">{subtitle}</h3>}
                {text}
            </div>
        }
        <div className={classNames('childnodes', twoPanel && 'two-panel')}>
            {children}
        </div>
        { overlay && <div className="overlay-wrapper">{overlay}</div> }
        </div>
}

GenericScreen.defaultProps = {
    fullwidth: false,
    style: {}
}

export default GenericScreen