import React from "react";
import Frame13213139362 from "../../Frame13213139362";
import Frame1321313908 from "../../Frame1321313908";
import BilockFill from "../../BilockFill";
import MobileMenu from "../../MobileMenu";
import "./X2Page6.sass";
import { x2Page6Data } from "./values";
import Screen from "../../Screen";
import AdaptiveBlock from "../../TwoColBlock";
import FeatureCarousel from "../FeatureCarousel";

const phoneImages = [
  '/img/phone1.png',
  '/img/phone2.png',
  '/img/phone3.png'
]

const MobileScheme = (props) => {
  return <div className="mobile-scheme">
    {
      phoneImages.map(img => <div key={img}><img src={img}/></div>)
    }
  </div>
}

function X2Page6(props) {
  const {
    x2Page_6,
    spanText1,
    overlapGroup4,
    frame1359979,
    group1359765,
    fill24,
    frame13599801,
    group1359755,
    wcVideoCallWithChat,
    spanText2,
    cellularConnection1,
    wifi1,
    battery1,
    overlapGroup1,
    vector2,
    group1359868,
    frame13599802,
    group1359866,
    incomingCall,
    frame,
    intercomMobileDoorOnly,
    spanText3,
    cellularConnection2,
    wifi2,
    battery2,
    screenShine,
    notch,
    camera,
    x2Page_7,
    x2Page_7_Mobile,
    spanText4,
    spanText5,
    spanText6,
    line311,
    iphonexsSpaceGrayscreen1,
    spanText7,
    line312,
    iphonexsSpaceGraynewfrtre1,
    spanText8,
    line313,
    iphonexsSpaceGraynewnew2Frtre1,
    spanText9,
    line314,
    iphonexsSpaceGraynew2,
    spanText10,
    line315,
    iphonexsSpaceGraynew8,
    spanText11,
    line316,
    iphoneVer11,
    spanText12,
    line317,
    iphonexsSpaceGraynew1,
    spanText13,
    line318,
    group401,
    spanText14,
    line319,
    spanText15,
    line3110,
    intercomMobile,
    group1359750,
    group1359749,
    spanText16,
    line3111,
    frame13213139362Props,
    frame13213139081Props,
    bilockFillProps,
    frame13213139082Props,
    mobileMenuProps,
  } = x2Page6Data;

  return (
    <>
    <div className="content-screen page-sized">
          <div className="frame-1321314084">
            <div className="frame-1321314083">
              <div className="how-it-works montserrat-bold-steel-gray-36px">
                <span className="montserrat-bold-steel-gray-36px">{spanText1}</span>
              </div>
              <AdaptiveBlock>
                <div className="frame-1321313958">
                  <Frame1321313908
                    spanText1={frame13213139081Props.spanText1}
                    spanText2={frame13213139081Props.spanText2}
                  />
                  <MobileScheme/>
                </div>
                <div className="frame-1321313957">
                  <Frame1321313908
                    spanText1={frame13213139082Props.spanText1}
                    spanText2={frame13213139082Props.spanText2}
                    className={frame13213139082Props.className}
                  />
                  <div className="overlap-group1-52">
                    <div className="group-1359868-6" style={{ backgroundImage: `url(${group1359868})` }}>
                      <div className="frame-1359980-1" style={{ backgroundImage: `url(${frame13599802})` }}></div>
                    </div>
                    <img className="group-1359866-8" src={group1359866} />
                    <img className="incoming-call-1" src={incomingCall} />
                    <div className="overlap-group-125">
                      <img className="frame-16" src={frame} />
                      <div className="put-screen-in-here-16">
                        <img className="intercom-mobile-door-only-6" src={intercomMobileDoorOnly} />
                      </div>
                      <div className="system-bar-white-7">
                        <div className="time-13 sfprotext-semi-bold-white-3-6px">
                          <span className="sfprotext-semi-bold-white-3-6px">{spanText3}</span>
                        </div>
                        <img className="cellular-connection-11" src={cellularConnection2} />
                        <img className="wifi-13" src={wifi2} />
                        <img className="battery-13" src={battery2} />
                      </div>
                      <div className="home-indicator-white-16"></div>
                      <img className="screen-shine-15" src={screenShine} />
                      <div className="hardware-16">
                        <div className="flex-col-43">
                          <div className="switch-16"></div>
                          <div className="volume-up-button-16"></div>
                          <div className="volume-down-button-16"></div>
                        </div>
                        <div className="notch-17" style={{ backgroundImage: `url(${notch})` }}>
                          <div className="speaker-14"></div>
                          <img className="camera-13" src={camera} />
                        </div>
                        <div className="power-button-16"></div>
                      </div>
                    </div>
                  </div>
                </div>
                </AdaptiveBlock>
            </div>
          </div>
        </div>
        <div className="content-screen page-sized">
          <Screen
            className={'column-layout'}
          >
            <div className="screen-subtitle">
                {spanText4}
              </div>
              <div className="screen-content">
                {spanText5}
              </div>
            <FeatureCarousel/>
          </Screen>
      </div>
      </>
  );
}

export default X2Page6;
