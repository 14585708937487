import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import {paths} from '../../const/paths';
import './MultiColumnNavbar.sass'

// links are added thru a two-level array:
// [ column: [row1, row2, row3..]] 
// each entry is either a string (points to an entry on paths const), or an object {path: string, title: title}

const populateColumn = (column) => {
    return column
        .map((item) => {
        if (typeof item === 'string') return {...paths[item], path: item}
        return item
        })
        .filter((o) => Boolean(o))
}

const LinkColumn = (props) => {
    const { column, columnClassName } = props;

    return <div className={classNames('multicolumn-navbar-column', columnClassName)}>
    {
        populateColumn(column).map((item) => <a href={item.path} key={item.path}>
            <span>{item.title}</span>
        </a>)
    }
    </div>
}

const MultiColumnNavbar = (props) => {
    const {
        className,
        columnClassName,
        links
    } = props;

    return <div className={classNames('multicolumn-navbar', className)}>
        {
            links.map((column) => 
                <LinkColumn
                    className={columnClassName}
                    key={`${column[0]}_col`}
                    column={column}
                />)
        }
    </div>
}

export default MultiColumnNavbar