import React from "react";
import "./Frame13213139152.sass";

function Frame13213139152(props) {
  const { group, className } = props;

  return (
    <div className={`frame-1321313915-5-1 ${className || ""}`}>
      <img className="group-27" src={group} />
      <div className="video montserrat-normal-regent-gray-18px">
        <span className="span-54 montserrat-normal-regent-gray-18px">Video</span>
      </div>
    </div>
  );
}

export default Frame13213139152;
