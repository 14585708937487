import classNames from 'classnames';
import React from 'react';
import './AdaptiveBlock.sass'

const AdaptiveBlock = (props) => {
    const {
        children,
        verticalOrder,
        className,
        columns
     } = props;

    return <div
        className={classNames('content-block', verticalOrder, columns && 'column-layout', className)}
        style={{'--columns':columns}}
        >
        { children }
    </div>
}

AdaptiveBlock.defaultProps = {
    verticalOrder: 'lr'
}

export default AdaptiveBlock