import React, { useState } from 'react';
import MulticardScreen from '../ContentScreens/MulticardScreen';
import ReactCountryFlag from 'react-country-flag'
import Modal from '../Modal';
import './LinkWithRegionPicker.sass'
import appendProps from '../../helpers/appendProps';
import regions from '../../const/regions';
import ChromeIcon from '../Icons/ChromeIcon';

const LinkWithRegionPicker = (props) => {
    const { hrefs, children } = props;
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const handleCloseModal = (e) => {
        e && e.stopPropagation();
        setIsModalOpen(false)
    }
    return <>
    { appendProps(children, {onClick: ()=>{setIsModalOpen(true)}})}

    {isModalOpen && <Modal>
    <div
        className='modal-overlay'
        onClick={handleCloseModal}
        >
        <MulticardScreen
            subtitle={'Please choose your nearest region'}
            text={<div className='browserhint'><ChromeIcon/> <span>Please use Google Chrome web browser to register/login at all times.</span></div>}
            cards={
                Object.entries(hrefs).map(([country, link]) => ({
                    onClick: ()=> {
                        handleCloseModal()
                        window.open(link, '_blank')
                    },
                    title: <ReactCountryFlag
                        svg
                        countryCode={(country === 'uk' )? 'GB' : country.toUpperCase()}
                    />,
                    subtitle: regions[country] || country.toUpperCase()
                }))
            }
        />
    </div>
    </Modal>}
    </>
}

export default LinkWithRegionPicker