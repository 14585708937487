import React from "react";
import "./Frame1321313898.sass";

function Frame1321313898(props) {
  const { spanText1, spanText2, className } = props;

  return (
    <div className={`frame-1321313898-3 ${className || ""}`}>
      <div className="video-concierge-3 montserrat-bold-steel-gray-36px">
        <span className="span-5 montserrat-bold-steel-gray-36px">{spanText1}</span>
      </div>
      <div className="no-more-staff-shorta-1 montserrat-normal-regent-gray-18px">
        <span className="span-5 montserrat-normal-regent-gray-18px">{spanText2}</span>
      </div>
    </div>
  );
}

export default Frame1321313898;
