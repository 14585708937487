import React from 'react';
import GenericScreen from '../../components/GenericScreen';
// import SplashScreen from '../../components/SplashScreen';
import X3Page7 from '../../components/FonecomPage/X3Page7';
import { Link } from 'react-router-dom'
import { purchasePaths } from '../../const/paths';
import {
    conciergeFeatures,
    x4Page1Data,
    x4Page2Data,
    x4Page3Data,
    x4Page4Data,
    x4Page5Data,
    x4Page6Data,
    x4Page7Data,
    x4Page8Data
} from './values';

import './VideoConcierge.sass'
import SplashScreen from '../../components/ContentScreens/SplashScreen';
import MulticardScreen from '../../components/ContentScreens/MulticardScreen';
import Image from '../../components/Image';
import classNames from 'classnames';
import { desktopOnlyClass } from '../../const/styling';
import LinkWithRegionPicker from '../../components/LinkWithRegionPicker';

const FeatureList = (props) => {
    const {
        features
    } = props;
    return <div className='concierge-pricingplans'>
        <div className='purchase-links'>
            <LinkWithRegionPicker
                hrefs={purchasePaths.signup}
            >
                <a>Try for 30 Days</a>
            </LinkWithRegionPicker>
            <LinkWithRegionPicker
                hrefs={purchasePaths.signup}
            >
                <a>Buy now</a>
            </LinkWithRegionPicker>
        </div>
        <div className='subscriptionlength'>
        12 (or) 36 month subscription
        </div>
        <ul>
        {
            features.map((feature) => <li className='plan-feature' key={feature}>{feature}</li>)
        }
        </ul>
    </div>
}


const VideoConciergePage = (props) => {
    return <>
            <SplashScreen
                title={'Video Concierge 3.0'}
                text={<div className='text'>Your concierge, reimagined.<br/>
                Your front of house staff no longer need to be front of house.<br/>
                    With our revolutionary Video Concierge Cloud-Platform, they can be anywhere and go anywhere.</div>}
                picture={'/img/concierge-full-splash.png'}
            />
            <MulticardScreen
                className={'concierge-collaboration'}
                cards={[
                    {
                        title: <>Deploy human staff, <br/>digitally, on-demand.<br/><br/>Instantly.</>,
                        subtitle: 'While video conferencing platforms like Zoom and Teams can only provide remote collaboration, Video Concierge 3.0 can assist in deploying, scaling and redirecting your workforce where they’re needed most. Plus, your team can enjoy total control across any device and operating system, from iPad to Windows, Mac or Linux PC.'
                    },
                    {
                        background: '/img/concierge1.png'
                    }
                ]}
            />
            <MulticardScreen
                className={'concierge-proactive'}
                cards={
                    [
                        {
                            title: `Touch or Touch-less`,
                            subtitle: `Voice Activated, Video Message Played (Optional)`,
                            attachment: <Image className='concierge-cardbg' src='/img/concierge2.png'/>,
                        },
                        {
                            title: `Patented Jump Screen Technology*`,
                            subtitle: `Move with your customer proactively from screen to screen.`,
                            attachment: <Image className='concierge-cardbg' src='/img/concierge3.png'/>,
                        }
                    ]
                }
            />
            <MulticardScreen
                className={'concierge-web'}
                cards={
                    [
                        {
                            title: `Manage calls and upload digital content`,
                            attachment: <Image className='concierge-cardbg' src="/img/concierge4.png"/>
                        },
                        {
                            title: `Answer calls from different locations`,
                            attachment: <Image className='concierge-cardbg' src="/img/concierge5.png"/>
                        }
                    ]
                }
                // overlay = {<>
                //     <div className={classNames('concierge5-arrow top-arrow', desktopOnlyClass)}/>
                //     <div className={classNames('concierge5-arrow middle-arrow', desktopOnlyClass)}/>
                //     <div className={classNames('concierge5-arrow lower-arrow', desktopOnlyClass)}/>
                //     </>
                // }
            />
            
            <MulticardScreen
                className={'concierge-new-features'}
                title={'Brand new cutting edge features...'}
                cards={[
                    {
                        title: `Voice-to-text translation`,
                        subtitle: `Up to 60 languages`,
                        attachment: <Image className='concierge-cardbg' src='/img/concierge6.png'/>,
                    },
                    {
                        title: `Web-Relay Integration`,
                        subtitle: `Open doors/lift floors remotely.`,
                        attachment: <Image className='concierge-cardbg' src='/img/concierge7.png'/>,
                    }
                ]}
            />
            <MulticardScreen
                className={'concierge-customer-experience'}
                cards = {[
                    {
                        className: 'centered-content-card',
                        title: `Designed for you to provide the best Customer Experience…`,
                        subtitle: `Add static or video signage, or upload pre-recorded video messages to automate repetitive tasks.`, 
                        attachment: <Image src='/img/canihelp.png' className={classNames('canihelpyou', desktopOnlyClass)}/>
                    },
                    {
                        background: '/img/concierge8.png',
                    }
                ]}

            />
            <MulticardScreen
                className={'concierge-offers-screen'}
                cards={[
                    {
                        title: `Basic`,
                        attachment: <FeatureList features={conciergeFeatures.basic}/>,
                        className: 'pricing-plan-card'
                    },
                    {
                        title: `Advanced`,
                        attachment: <FeatureList features={conciergeFeatures.advanced}/>,
                        className: 'pricing-plan-card'
                    }

                ]}
            />

            <X3Page7/>
        </>
}

export default VideoConciergePage;