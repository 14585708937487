import React from "react";
import "./Frame1321313908.sass";

function Frame1321313908(props) {
  const { spanText1, spanText2, className } = props;

  return (
    <div className={`frame-1321313908-2 ${className || ""}`}>
      <div className="for montserrat-semi-bold-steel-gray-24px">
        <span className="span-152 montserrat-semi-bold-steel-gray-24px">{spanText1}</span>
      </div>
      <div className="visitors-simply-scan montserrat-normal-regent-gray-18px">
        <span className="span-152 montserrat-normal-regent-gray-18px">{spanText2}</span>
      </div>
    </div>
  );
}

export default Frame1321313908;
