import React from "react";
import "./BilockFill.sass";

function BilockFill(props) {
  const { src, className } = props;

  return (
    <div className={`bilock-fill-1 ${className || ""}`}>
      <img className="group-52" src={src} />
    </div>
  );
}

export default BilockFill;
