import React from "react";
import Frame1321313913 from "../Frame1321313913";
import "./Frame1321313917.sass";

function Frame1321313917(props) {
  const { group1, group2, className, frame1321313913Props } = props;

  return (
    <div className={`frame-1321313917 ${className || ""}`}>
      <Frame1321313913 group={frame1321313913Props.group} />
      <div className="frame-1321313914">
        <img className="group-11" src={group1} />
        <div className="voice-10-calls montserrat-normal-regent-gray-18px">
          <span className="span-43 montserrat-normal-regent-gray-18px">Voice -&nbsp;&nbsp;10 Calls</span>
        </div>
      </div>
      <div className="frame-1321313915">
        <img className="group-12" src={group2} />
        <div className="video-10-calls montserrat-normal-regent-gray-18px">
          <span className="span-43 montserrat-normal-regent-gray-18px">Video - 10 Calls</span>
        </div>
      </div>
    </div>
  );
}

export default Frame1321313917;
