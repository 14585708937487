const getViewportSize = () => {

    if ( window.innerWidth ) {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    return {
        width: window.document.documentElement.clientWidth,
        height: window.document.documentElement.clientHeight
    }
}

export default getViewportSize