import React from 'react';
import ContactSection from '../../components/FooterComponents/ContactSection';
import MultiColumnNavbar from '../../components/MultiColumnNavbar';
import { fullPathOrder } from '../../const/paths';
import './FooterContainer.sass'


// nav column content is pulled from paths const
// names in arrays are paths

const footerNavColumns = [
    [
        'home',
        'technologies',
        'awards',
        'about'
    ],
    [
        'intercoms',
        'qrvideo',
        'outdoor'
    ],
    [
        'videoconcierge'
    ],
    [
        'haas',
    ],
    [
        'cases',
        'facilities',
        'hospitals',
        'transport',
        'highschool'
    ]

]

const FooterContainer = (props) => {
    return <div className='container-footer' id='contact'>
        <MultiColumnNavbar
            links={fullPathOrder}
            columnClassName={'montserrat-normal-regent-gray-18px'}
            className={'container-footer-section screen'}

        />
        <ContactSection className="screen"/>
    </div>
}

export default FooterContainer