import React from "react";
import "./Frame1321313913.sass";

function Frame1321313913(props) {
  const { group } = props;

  return (
    <div className="frame-1321313913">
      <img className="group-19" src={group} />
      <div className="text-chat montserrat-normal-regent-gray-18px">
        <span className="montserrat-normal-regent-gray-18px">Text Chat</span>
      </div>
    </div>
  );
}

export default Frame1321313913;
