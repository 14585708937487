import React from 'react'
import QRCode from 'qrcode.react'
import classNames from 'classnames'

const QRCodeLink = (props) => {
    const {
        href,
        className
    } = props
    const qrCodeProps = {
        renderAs: 'svg',
        includeMargin: true,
        href,
        className
    }
    
    return <div className={classNames('qrcode', className)}>
            <QRCode value={href} {...qrCodeProps}/>
        </div>
}

export default QRCodeLink