import React from 'react';
import X2Page2 from '../../components/IntercomsPage/X2Page2';
import X2Page3 from '../../components/IntercomsPage/X2Page3';
import X2Page4 from '../../components/IntercomsPage/X2Page4';
import X2Page5 from '../../components/IntercomsPage/X2Page5';
import X2Page6 from '../../components/IntercomsPage/X2Page6';
import X2Page9 from '../../components/IntercomsPage/X2Page9';
import X3Page7 from '../../components/FonecomPage/X3Page7';
import { 
    x2Page2Data,
} from './values';
import SplashScreen from '../../components/ContentScreens/SplashScreen';
import MulticardScreen from '../../components/ContentScreens/MulticardScreen';
import Image from '../../components/Image';

import './Qrideo.sass'
import { cardBackgroundColors } from '../../components/Card';
import classNames from 'classnames';
import { desktopOnlyClass, mobileOnlyClass } from '../../const/styling';
import { BrowserView, MobileView } from 'react-mobile-detect';
import FeatureCarousel from '../../components/IntercomsPage/FeatureCarousel';
import QRCodeLink from '../../components/QRcodeLink';
import { qrideoPaths } from '../../const/paths';

const thirdPartyLogoPrefix = "3p"
const thirdPartyLogoCount = 9
const phoneImages = [
    '/img/phone1.png',
    '/img/phone2.png',
    '/img/phone3.png'
  ]

const QrideoPage = (props) => {
    return (
        <>
        <SplashScreen
            title={'FONECOM'}
            subtitle={'Introducing QR Video Intercom'}
            text={<>
                <div className='text'>
                A new world of connection just a scan away
                </div>
                <div className='inline-flex'>
                        <a className="youtube-link" target="_blank" href='https://www.youtube.com/watch?v=eCZEiweInjs'>Residential</a>
                        <a className="youtube-link" target="_blank" href='https://www.youtube.com/watch?v=B5UmYolmDwY'>Commercial</a>
                </div>
            </>}
            picture='/img/phone-fullsize.png'
            className={'qrideo-splash'}
            // children={
            //     <Image
            //         src={'img/phone-fullsize.png'}
            //         className={mobileOnlyClass}
            //     />
            // }
        />
        <MulticardScreen
            cards={[
                {
                    title: 'Saying hello to future, starts with saying goodbye to the past',
                    subtitle: 'So say goodbye to bulky intercom hardware. Say goodbye to visitor wait times. Say goodbye to an unproductive workforce. With QR Video Intercom, simply place your QR Code anywhere for unparalleled communication and support.',
                    text: <>
                    <QRCodeLink href={qrideoPaths.commercial} className={classNames(desktopOnlyClass, 'qrcode-withpopup')}/>
                        <span className={desktopOnlyClass}>
                        Scan the code to see how QR Video Intercom can reimagine your commercial  sites
                    </span>
                </>
                },
                {
                    background: '/img/fonecom2visual.png'
                }
            ]}
        />
        <MulticardScreen
            cards={[
                {
                    background: "/img/fonecom3visual.png"
                },
                {
                    title: 'Ensure it’s the packages that drop off, not the productivity.',
                    subtitle: 'Large-scale loading docks, distribution centers and delivery areas can be complex and overwhelming. But with QR VIDEO INTERCOM, even the biggest facilities can be managed with the smallest teams.',
                    text: <>
                    <QRCodeLink href={qrideoPaths.warehouse} className={classNames(desktopOnlyClass, 'qrcode-withpopup')}/>
                        <span className={desktopOnlyClass}>
                        Scan the code to see how QR Video Intercom can reimagine your warehouse and logistics
                    </span>
                </>
                }
            ]}
        />
        <MulticardScreen
            screenBackground={"img/bgwithqr.png"}
            cards={
                [
                    {
                        title: 'Every door, floor and store at your fingertips',
                        subtitle: <div>
                            <span>
                        Whoever said you can only be in one place at a time, obviously hadn’t used QR Video Intercom. The cloud-based communication system gives you complete control over every point of every facility, so you can manage it all with ease.
                        </span>
                        <a href="https://www.youtube.com/watch?v=eCZEiweInjs" target={'_blank'} className={'youtube-link'}>How it works </a>
                        </div>,
                        text: <>
                    
                    <QRCodeLink href={qrideoPaths.commercial} className={classNames(desktopOnlyClass, 'qrcode-withpopup')}/>
                            <span className={desktopOnlyClass}>
                            Scan the code to see how QR Video Intercom can reimagine your commercial  sites
                        </span>
                    </>
                    },
                    {
                        background:'img/handWithPhone_mobile.png',
                        className: 'mobile-hand-background'
                    }
                ]
            }
            overlay={
                <Image src='img/handWithPhone.png' className={desktopOnlyClass}/>
            }
        />
        <MulticardScreen
            cards={
                [
                    {
                        bgColor: cardBackgroundColors.none,
                        attachment: <div className='logo-card'>
                            <div className={classNames('thirdparty-logos', (thirdPartyLogoCount % 2) && 'odd-count')}>
                            {
                                Array(thirdPartyLogoCount).fill("_").map((_, i)=>{
                                    return <Image key={i+1} src={`/img/${thirdPartyLogoPrefix}${i+1}.png`}/>
                                })
                            }
                            </div>
                            <Image src='/img/thirdparty.png' className={classNames(desktopOnlyClass, 'thirdparty-illustration')}/>
                        </div>
                    },
                    {
                        title: "Third party? Zero Problem",
                        subtitle: <span>
                            <span>Designed to effortlessly connect with popular products you use every day. You can now, quite literally, open the door and welcome people into a world of integrated access functionality.</span>
                            <br/>
                            <br/>
                            <a>List of Integrated partners > 
                            </a>
                            </span>,
                        text: <>
                    <QRCodeLink href={qrideoPaths.commercial} className={classNames(desktopOnlyClass, 'qrcode-withpopup')}/>
                            <span className={desktopOnlyClass}>
                            Scan the code to see how QR Video Intercom can reimagine your commercial  sites
                        </span>
                    </>
                    }

                ]
            }
        />
        <MulticardScreen
            cards={[
                {
                    title: 'For them',
                    subtitle: 'Visitors simply scan the QR Video Intercom code (no app required)',
                    attachment: <>
                        <BrowserView>
                            <div className='illustration_mobiles'>
                                {
                                    phoneImages.map((src) => <Image key={src} src={src} />)
                                }
                            </div>
                        </BrowserView>
                        <MobileView>
                            <Image src='img/phones_mobile_illustration.png'/>
                        </MobileView>
                    </>
                },
                {
                    title: 'For you',
                    subtitle: <ul>
                        <li>You receive a PUSH notification - Phone/Tablet/PC</li>
                        <li>You’ll see which QR entry has been scanned</li>
                        <li>Answer the call with the click of a button</li>
                        <li>Unlock doors and elevators whilst on the call</li>
                    </ul>,
                    attachment: <>
                    <BrowserView>
                        <Image src={'img/qrideo-laptop-desktop.png'}/>
                    </BrowserView>
                    <MobileView>
                        <Image src={'img/qrideo-laptop-mobile.png'}/>
                    </MobileView>
                    </>
                }
            ]}
        />
        <MulticardScreen
            className='feature-carousel-screen'
            cards={[
                {
                    bgColor: cardBackgroundColors.none,
                    className: 'feature-carousel-block',
                    title: <>Don’t just say hello to visitors.
                    <br/>
                    Say hello to the future of connection.
                    </>,
                        subtitle: `Calling QR Video Intercom just an intercom is like calling the iPhone just a phone.
                        Sure, it can connect you in all the familiar ways. But it also integrates with popular third parties; creating smart spaces, smart buildings and smart points wherever you want them.`,                    
                    attachment: <FeatureCarousel/>
                }
            ]}
        />
        <X3Page7/>
        <X2Page9/>
        </>
    )
}

export default QrideoPage