import classNames from 'classnames';
import React from 'react';

const Screen = (props) => {
    const {
        children,
        className
    } = props;
    return <div className={classNames('screen', className)}>
        { children }
    </div>
}

export default Screen