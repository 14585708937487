import React from "react";
import Frame1321313936 from "../Frame1321313936";
import Frame1321313898 from "../Frame1321313898";
import Frame1091343 from "../Frame1091343";
import Footer from "../Footer";
import "./HomePage.sass";
import LandingHeader from "../LandingHeader";
import { homePageData } from "./values";
import Screen from "../Screen";
import AdaptiveBlock from "../TwoColBlock";
import PartnerBlock from "./PartnerBlock";
import FindDealerBlock from "./FindDealerBlock";
import FeatureScreen from "./FeatureScreen";
import SplashScreen from "../ContentScreens/SplashScreen";
import MulticardScreen, { vOrders } from "../ContentScreens/MulticardScreen";
import screenContent from "./screenContent";

function HomePage(props) {
  const {
    spanText1,
    spanText2,
    qrCodeLanding,
    spanText3,
    spanText4,
    spanText5,
    frame13213139301,
  } = homePageData;

  return (
    <>
    <MulticardScreen
      className={'homesplash'}
      cards = {screenContent.SplashScreenBlock}
      vOrder = {vOrders.reversed} 
    />
      <FeatureScreen/>
      {/* <FindDealerBlock/> */}
      {/* <PartnerBlock/> */}
    </>
  );
}

export default HomePage;
