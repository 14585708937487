import React from "react";
import "./Frame1321313897.sass";

function Frame1321313897(props) {
  const { group1359843, group1359787, className } = props;

  return (
    <div className={`frame-1321313897-2 ${className || ""}`}>
      <div className="overlap-group-78">
        <img className="group-1359843-2" src={group1359843} />
        <img className="rectangle-6" src="/img/rectangle-8@2x.png" />
        <img className="ellipse-88-3" src="/img/ellipse-88-5@2x.png" />
        <img className="group-1359787-3" src={group1359787} />
      </div>
    </div>
  );
}

export default Frame1321313897;
