import React from "react";
import Group1359838 from "../Group1359838";
import Frame1321314062 from "../Frame1321314062";
import Frame1321313897 from "../Frame1321313897";
import Frame1321313959 from "../Frame1321313959";
import Frame1321313960 from "../Frame1321313960";
import Group1359902 from "../Group1359902";
import "./X5Page6.sass";

function X5Page6(props) {
  const {
    x5Page_6,
    spanText1,
    spanText2,
    spanText3,
    frame1321314061,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    overlapGroup,
    rectangle441,
    spanText8,
    group1359838Props,
    frame1321314062Props,
    frame1321313897Props,
    frame1321313959Props,
    frame1321313960Props,
    group1359902Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x5-page6 screen">
        <div className="overlap-group4-7">
          <div className="frame-1359712-2">
            <div className="the-intercom-reimagined-4 montserrat-bold-white-72px">
              <span className="montserrat-bold-white-72px">{spanText1}</span>
            </div>
            <div className="flex-row-24">
              <div className="ellipse-115-4"></div>
              <div className="group-1359873">
                <div className="as-a-service-at-your-service montserrat-bold-steel-gray-36px">
                  <span className="montserrat-bold-steel-gray-36px">{spanText2}</span>
                </div>
                <div className="whether-youre-in-se montserrat-normal-regent-gray-18px">
                  <span className="montserrat-normal-regent-gray-18px">{spanText3}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-group3-16">
            <Frame1321313897
              group1359843={frame1321313897Props.group1359843}
              group1359787={frame1321313897Props.group1359787}
              className={frame1321313897Props.className}
            />
            <div className="group-1359904-1">
              <Frame1321313959 className={frame1321313959Props.className} />
              <div className="ellipse-117-2"></div>
              <div className="ellipse-116-3"></div>
            </div>
            <div className="group-1359906-2">
              <div className="ellipse-117-3"></div>
              <div className="overlap-group1-37">
                <div className="ellipse-115-5"></div>
                <Frame1321313960
                  rectangle4={frame1321313960Props.rectangle4}
                  rectangle2={frame1321313960Props.rectangle2}
                  className={frame1321313960Props.className}
                />
              </div>
            </div>
            <div className="overlap-group2-19">
              <div className="ellipse-113-1"></div>
              <div className="frame-1321313962-3 border-14-7px-white">
                <div className="overlap-group-88" style={{ backgroundImage: `url(${overlapGroup})` }}>
                  <img className="rectangle-441-7" src={rectangle441} />
                  <div className="title-7 montserrat-medium-white-12-9px">
                    <span className="montserrat-medium-white-12-9px">{spanText8}</span>
                  </div>
                </div>
              </div>
            </div>
            <Group1359902 overlapGroup={group1359902Props.overlapGroup} className={group1359902Props.className} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default X5Page6;
