import React from "react";
import Frame1321314062 from "../../Frame1321314062";
import Frame1321313978 from "../../Frame1321313978";
import "./X2Page4.sass";
import Screen from "../../Screen";
import { x2Page4Data } from "./values";
import AdaptiveBlock from "../../TwoColBlock";
import Card from "../../Card";

const image = '/img/fonecom4visual.png'

function X2Page4(props) {
  const {
    x2Page_4,
    overlapGroup2,
    frame1321314061,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    overlapGroup,
    frame1321313903,
    frame1321314062Props,
    frame1321313978Props,
  } = x2Page4Data;

  return (
    <div className="content-screen page-sized qrideofullscreen">
      <Screen>
        <AdaptiveBlock
          columns={2}
        >
        <Card
          noTitle
          flexSize
          className='semitransparent'
        >
        <div className="overlap-group2-2" >
          <div className="overlap-group1-5">
            <div className="frame-1359889">
                <div className="every-door-floor-an montserrat-bold-steel-gray-36px">
                  <span className="montserrat-bold-steel-gray-36px">{spanText5}</span>
                </div>
                <div className="whoever-said-you-can montserrat-normal-regent-gray-18px">
                  <span className="montserrat-normal-regent-gray-18px">{spanText6}</span>
                </div>
              <Frame1321313978 screenshot20220225At16461={frame1321313978Props.screenshot20220225At16461} />
            </div>
          </div>
        </div>
        </Card>
        </AdaptiveBlock>
      </Screen>
    </div>
  );
}

export default X2Page4;
