import React from "react";
import "./Frame1359884.sass";

function Frame1359884(props) {
  const { className } = props;

  return (
    <div className={`frame-1359884 montserrat-normal-fiord-14px ${className || ""}`}>
      <div className="intercoms-2">
        <span className="span-3 montserrat-normal-fiord-14px">Intercoms</span>
      </div>
      <div className="video-concierge-1">
        <span className="span-3 montserrat-normal-fiord-14px">Video Concierge</span>
      </div>
      <div className="help-as-a-service-1">
        <span className="span-3 montserrat-normal-fiord-14px">Help As A Service</span>
      </div>
      <div className="contact-us">
        <span className="span-3 montserrat-normal-fiord-14px">Contact Us</span>
      </div>
    </div>
  );
}

export default Frame1359884;
