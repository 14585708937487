import React from "react";
import Frame13213139362 from "../../Frame13213139362";
import Frame1321313918 from "../../Frame1321313918";
import Frame1321313978 from "../../Frame1321313978";
import "./X2Page2.sass";
import Card from "../../Card";
import Screen from "../../Screen";
import AdaptiveBlock from "../../TwoColBlock";
import { x2Page2Data } from "./values";

const image = '/img/fonecom2visual.png';

function X2Page2(props) {
  const {
    x2Page_2,
    qr,
    frame13599791,
    frame13599792,
    frame13599793,
    frame13213139362Props,
    frame1321313918Props,
    frame1321313978Props,
  } = x2Page2Data;

  return (
    <div className="content-screen page-sized">
      <Screen>
        <AdaptiveBlock
          columns={2}
        >
        <Card
          title={frame1321313918Props.spanText1}
          flexSize
        >
            <div className='block-description'>
                <div className="description">{frame1321313918Props.spanText2}</div>
            </div>
            <Frame1321313978
              screenshot20220225At16461={frame1321313978Props.screenshot20220225At16461}
              className={frame1321313978Props.className}
            />
        </Card>
        <img src={image} className='contentitem'/>
          </AdaptiveBlock>
      </Screen>
    </div>
  );
}

export default X2Page2;
