import React from "react";
import "./Group1359902.sass";

function Group1359902(props) {
  const { overlapGroup, className } = props;

  return (
    <div className={`group-1359902-1 ${className || ""}`}>
      <div className="overlap-group1-33">
        <div className="ellipse-115-2"></div>
        <div className="frame-1321313961 border-14-7px-white">
          <div className="overlap-group-83" style={{ backgroundImage: `url(${overlapGroup})` }}>
            <img className="rectangle-441-5" src="/img/rectangle-441-8@2x.png" />
            <div className="title-5 montserrat-medium-white-12-9px">
              <span className="spanhhhhc montserrat-medium-white-12-9px">Security Guard</span>
            </div>
          </div>
        </div>
      </div>
      <div className="ellipse-116-1"></div>
    </div>
  );
}

export default Group1359902;
