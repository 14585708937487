import React from 'react';
import { cardBackgroundColors } from '../../../components/Card';
import MulticardScreen from '../../../components/ContentScreens/MulticardScreen';
import './OutdoorFeatures.sass'
import Screen from '../../../components/Screen';
import AdaptiveBlock from '../../../components/TwoColBlock';

const outdoorFeatures = {
    hd: {
        icon: '/img/icon-hd.png',
        caption: 'Full Screen Video HD Display'
    },
    phone: {
        icon: '/img/icon-phone.png',
        caption: 'iPhone/Android apps, or a simple phone call'
    },
    aes: {
        icon: '/img/icon-lock.png',
        caption: 'AES-256 bit media encryption (peer-to-peer)'
    },
    video: {
        icon: '/img/icon-video.png',
        caption: '2 way video, voip or voice calls'
    },
    network: {
        icon: '/img/icon-network.png',
        caption: 'Connect to Concierge or Security'
    },
    light: {
        icon: '/img/icon-light.png',
        caption: 'LED Light'
    },
    camera: {
        icon: '/img/ic-round-photo-camera-1@2x.png',
        caption: 'Logitec camera'
    },
    waterproof: {
        icon: '/img/mdi-water-check-1@2x.png',
        caption: 'IP 65 Water Resistant / Dust Proof'
    },
    loudspeaker: {
        icon: '/img/icon-speaker.png',
        caption: 'Loud Speaker System'
    },
    motherboard: {
        icon: '/img/simple-icons-asus-1@2x.png',
        caption: 'ASUS Motherboard'
    },
    cpu: {
        icon: '/img/cib-intel-1@2x.png',
        caption: 'Intel Processor'
    }
}

const OutdoorFeatureEntry = (props) => {
    const {
        icon,
        caption
    } = props;
    return <div className='outdoor-feature'>
        <div>
            <img src={icon}/>
        </div>
        <div>
            {caption}
        </div>
    </div>
}

const OutdoorFeatures = (props) => {

    return (
    <MulticardScreen
        className='outdoor-features'
        title='Features'
        cards={
            [
                {
                    bgColor: cardBackgroundColors.none,
                    attachment: <>{
                        Object.values(outdoorFeatures).map((val, i) => i < 5 && <OutdoorFeatureEntry key={val.icon} {...val}/>)
                    }</>
                },
                {
                },
                {
                    bgColor: cardBackgroundColors.none,
                    attachment: <>{
                        Object.values(outdoorFeatures).map((val, i) => i >= 5 && <OutdoorFeatureEntry key={val.icon} {...val}/>)
                    }</>
                }
            ]
        }
    />
//     <div className='content-screen outdoor6'>
//     <Screen className={'outdoor-feature-screen'}>
//         <div className='screen-subtitle'>
//             Features
//         </div>
//         <AdaptiveBlock
//             columns={2}
//             className={'outdoor-feature-table'}
//         >
//         <div className='outdoor-feature-column'>
//         {
//             Object.values(outdoorFeatures)
//                 .filter((_, id) => id < 5)
//                 .map(feature => <OutdoorFeatureEntry key={feature.caption} {...feature}/>)
//         }
//         </div>
//         <div className='outdoor-feature-column'>
//         {
//             Object.values(outdoorFeatures)
//                 .filter((_, id) => id >= 5)
//                 .map(feature => <OutdoorFeatureEntry key={feature.caption} {...feature}/>)
//         }
//         </div>  
//         </AdaptiveBlock>
//     </Screen>
// </div>
)
}

export default OutdoorFeatures;