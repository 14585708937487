import React from 'react';
import GenericScreen from '../../components/GenericScreen';
import Screen from '../../components/Screen';
import SplashScreen from '../../components/ContentScreens/SplashScreen';
import AdaptiveBlock from '../../components/TwoColBlock';
import X5Page5 from '../../components/X5Page5';
import X5Page6 from '../../components/X5Page6';
import X5Page7 from '../../components/X5Page7';
import X5Page8 from '../../components/X5Page8';

import './HaasPage.sass'

import { BrowserView, MobileView } from 'react-mobile-detect'
import { 
    x5Page5Data,
    x5Page6Data,
    x5Page7Data,
    x5Page8Data

 } from './values';
import X2Page9 from '../../components/IntercomsPage/X2Page9';
import MulticardScreen from '../../components/ContentScreens/MulticardScreen';
import Image from '../../components/Image';
import classNames from 'classnames';
import { plans } from '../../const/plans';
import { desktopOnlyClass } from '../../const/styling';
import { cardBackgroundColors } from '../../components/Card';

const HaasIllustrationScreen = (props) => {
    const { screenTitle } = props;
    return (
        <>
        <BrowserView>
        <Screen
            className={'column-layout'}
        >
            {
                screenTitle && <div className='screen-title'>{screenTitle}</div>
            }
            <AdaptiveBlock
                className={'genericscreen'}
                columns={2}
            >
            </AdaptiveBlock>
        </Screen>
        </BrowserView>
        </>
    )
}


const HaasPage = (props) => {
    return <>
        <SplashScreen
            title={'Help-as-a-Service'}
            picture={'/img/haas_splash.png'}
            text={<div>
                {`Making small work of large scale operations.`}
                <br/>
                {`The way we communicate and deploy staff in today’s hybrid workplace is evolving. `}
                <br/>
                <b>Take it to the next level. </b>
            </div>}
        />
        <MulticardScreen
            cards={[
                {   
                    className:'centered-content-card',
                    title:'Add the personal touch to every touchpoint',
                    subtitle:'Dealing with a customer requirements across a multitude of different access points is a challenge. But service at scale no longer comes with a drop off in efficiency or personal support. Teleportivity’s “remote” as-a-service model means that, no matter what your requirements, we can support your staff and customers across hundreds and even thousands of access points with ease.'
                },
                {
                    background: '/img/haas1.png'                    
                }
            ]}
        />
        <MulticardScreen
            cards={[
                {
                    background: '/img/haas2.png'                    
                },
                {
                    className:'centered-content-card',
                    title: <>Many codes make<br/>light work</>,
                    subtitle: 'From business cards to invoices to nationwide support sites, no task is too big to add an unforgettable point of connection with our easily updatable, completely bespoke QR Code solutions. Simply print and place a Teleportivity QR Code, and every single object, space and place becomes an ultra-efficient, seamlessly managed customer support system.'
                }

            ]}
        />
        <MulticardScreen
            cards={[
                {
                    className:'centered-content-card',
                    title: <>Your customers will<br/>thank you. Face to face.</>,
                    subtitle: 'For a customer, there’s nothing as frustrating as being treated like a number – endlessly shuffled around an inefficient system that makes you have to repeat the same information over and over.  But with our Help-As-A-Service, customers simply need to scan a Teleportivity QR code on their device, and they can choose from a range of options to help them solve their issues, first time, every time. '    
                },
                {
                    background: '/img/haas3.png'
                }
            ]}
        />

       <MulticardScreen 
        title={'Help-as-a-service'}
        className={classNames(desktopOnlyClass, 'staff-screen')}
        subtitle={<>
            Create your own “as-a-service” or “managed service” offering
            <br/>
            Use YOUR staff, or our global ‘white label’ staffed options.
            </>
        }
        screenBackground={'/img/haas-consultation-bg.png'}
        cards={[
            {
                bgColor: cardBackgroundColors.none,
                attachment: <div className='staff'>
                    <div className='employee direct'>
                        <div className='job'>AV Live</div>
                        <Image className='photo' src='img/haas-staff-1.png'/>
                        <Image className='qr' src='img/sampleqr1.png'/>
                    </div>
                    <div className='employee direct'>
                        <div className='job'>Security</div>
                        <Image className='photo' src='img/haas-staff-2.png'/>
                        <Image className='qr' src='img/sampleqr1.png'/>
                    </div>
                </div>
            },
            {
                bgColor: cardBackgroundColors.none,
                attachment: <div className='staff'>
                    <div className='employee reverse'>
                        <div className='job'>Concierge Live</div>
                        <Image className='photo' src='img/haas-staff-3.png'/>
                        <Image className='qr' src='img/sampleqr1.png'/>
                    </div>
                    <div className='employee reverse'>
                        <div className='job'></div>
                        <div className='photo blank'>
                            <span>
                            Your own 
                            “As-a-service” 
                            Offering
                        </span>
                        </div>
                    </div>
                </div>
            }
        ]}
       />
        <MulticardScreen
            cards={[
                {
                    background: '/img/haas_splash.png'     
                },
                {
                    className:'centered-content-card',
                    title: 'As-A-Service at your service',
                    subtitle: 'Whether you’re in security or sales; whether you oversee facilities or product support; whether you deal in AV or CX, our As-A-Service model can help you operate at maximum scale and efficiency. Forget backlogs and bottlenecks – Teleportivity means one set of hands can support one hundred customers with ease.'                      
                }
            ]}
        />
        <MulticardScreen
             screenBackground='/img/haas5.png'
             cards={[
                 {
                    className: classNames('centered-content-card', 'blurred'),
                title: 'Help-Desk AV/IT Support reimagined',
                subtitle: 'with Live Video Help-Desk Support',
                text: 'No matter how many rooms, spaces or sites you need to cover, our Live Help Desk makes small work of large scale support.',                 
             },
             {}
            ]}
            overlay={
                <BrowserView>
                    <Image className='hands-overlay' src='/img/haas-hands-overlay.png'/>
                </BrowserView>
            }       
        />
        <X2Page9
            plans={{
                free: plans.free,
                haas: {...plans.home, name: 'Help-As-A-Service'}
            }}
        />
    </>
}

export default HaasPage