import React from 'react';

const frame132131406223Data = {
    className: "",
};

const frame132131393632Data = {
    frame13213140622Props: frame132131406223Data,
};

const frame132131406222Data = {
    className: "",
};

const frame132131393631Data = {
    frame13213140622Props: frame132131406222Data,
};

const rectangleButton51Data = {
    rectangleButton: "/img/oval-18@2x.png",
    fontistoKey: "/img/fontisto-key-3@2x.png",
};

const rectangleButton52Data = {
    fontistoKey: "/img/oval-18@2x.png",
    rectangleButton: "/img/group-8@2x.png",
    className: "rectangle-button-18",
};

const rectangleButton53Data = {
    fontistoKey: "/img/oval-18@2x.png",
    rectangleButton: "/img/group-9@2x.png",
    className: "rectangle-button-16-1",
};

const rectangleButton54Data = {
    fontistoKey: "/img/oval-17@2x.png",
    rectangleButton: "/img/ant-design-home-filled-3@2x.png",
    className: "rectangle-button-16",
};

export const x3Page1Data = {
    x3Page_1: "/img/3-page-1-1@2x.png",
    spanText1: <>FONECOM <br />Outdoor Display</>,
    spanText2: "Make an unforgettable first impression, from anywhere",
    overlapGroup2: "/img/shadows-5@2x.png",
    frontIntercom1: "/img/front-intercom-1-2@2x.png",
    unsplash6Zfvl4Yuam: "/img/unsplash--6zfvl4yuam-3@2x.png",
    spanText3: "106 Postman Avenue",
    spanText4: <>Welcome <br />to our Building</>,
    combinedShape: "/img/combined-shape-3@2x.png",
    spanText5: "Search",
    group: "/img/group-7@2x.png",
    unsplashT2SaiAqipi: "/img/unsplash-t2sai-aqipi-3@2x.png",
    spanText6: "Company Name",
    spanText7: "New York, NY, 4715 5 Avenue",
    spanText8: "1 (333) 231- 12-67",
    rectangleButton1: "/img/oval-18@2x.png",
    iconConcierge: "/img/icon--concierge-3@2x.png",
    rectangleButton2: "/img/oval-18@2x.png",
    spanText9: "911",
    union: "/img/union-3@2x.png",
    subtract: "/img/subtract-3@2x.png",
    frame13213139363Props: frame132131393631Data,
    rectangleButton51Props: rectangleButton51Data,
    rectangleButton52Props: rectangleButton52Data,
    rectangleButton53Props: rectangleButton53Data,
    rectangleButton54Props: rectangleButton54Data,
};