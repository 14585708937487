import React from 'react';

export const conciergeFeatures = {
    basic: [
        'Basic Features',
        'Tap/Voice Activation',
        'Reactive Calling',
        'Single Call Group',
        'Single Static Digital Sign',
        'PC Only',
        '3x Intercom Operators (not upgradable)'
    ],
    advanced: [
        'Advanced Features',
        'Tap/Voice Activation',
        'Proactive & Reactive Calling',
        'Jump Screen / Jump to Phone',
        'Multiple Call Groups Available',
        'Multiple Static & Video Digital Messages / Signs',
        'PC / iPad',
        '3x Intercom Operators (upgradable)',
        'Voice to Text Translation (upgradable)',
        'Connect with Inception/Integriti* (coming soon)'
    ]
}

const iconElectronSay6Data = {
    src: "/img/union-7@2x.png",
};

const frame1321314062216Data = {
    className: "frame-1321314062-31",
};

const frame132131393657Data = {
    frame13213140622Props: frame1321314062216Data,
};


const frame1321314062214Data = {
    className: "",
};

const frame132131393655Data = {
    frame13213140622Props: frame1321314062214Data,
};

export const x4Page1Data = {
    frame13213139365Props: frame132131393655Data,
};


const frame1321314062210Data = {
    className: "",
};

const frame132131393651Data = {
    frame13213140622Props: frame1321314062210Data,
};

export const x4Page2Data = {
    x4Page_2: "/img/4-page-2-1@2x.png",
    spanText1: <>Deploy human staff, digitally, on-demand. <br />Instantly.</>,
    spanText2: <>While video conferencing platforms like Zoom and Teams can only provide remote collaboration, Video Concierge 3.0 can assist in deploying, scaling and redirecting your workforce where they’re needed most.<br />Plus, your team can enjoy total control across any device and operating system, from iPad to Windows, Mac or Linux PC.</>,
    overlapGroup: "/img/monitor-3@2x.png",
    rectangle4: "/img/rectangle-4-3@1x.png",
    unsplashBkesvqfxass: "/img/unsplash-bkesvqfxass-1@2x.png",
    frame13213139365Props: frame132131393651Data,
};

const frame1321314062215Data = {
    className: "",
};

const frame132131393656Data = {
    frame13213140622Props: frame1321314062215Data,
};

export const x4Page3Data = {
    x4Page_3: "/img/4-page-3@1x.png",
    spanText1: "How it works for your visitor/customer?",
    tv1: "/img/tv-1@1x.png",
    group: "/img/group-61@2x.png",
    nn_20211004__12081: "/img/-------------n----n---------2021-10-04----12-08-1-1@2x.png",
    spanText2: "Touch or Touch-less",
    spanText3: " Voice Activated, Video Message Played (Optional)",
    vector42: "/img/vector-43-1@2x.png",
    vector43: "/img/vector-43-1@2x.png",
    tv2: "/img/tv-4@1x.png",
    nn_20211004__120711: "/img/-------------n----n---------2021-10-04----12-07-1-2@2x.png",
    spanText4: "2",
    spanText5: "floor",
    spanText6: "Jump from screen...",
    tv3: "/img/tv-2@1x.png",
    nn_20211004__120712: "/img/-------------n----n---------2021-10-04----12-07-1-1@2x.png",
    spanText7: "3",
    spanText8: "floor",
    spanText9: "..to screen",
    tv4: "/img/tv-3@1x.png",
    x32438691: "/img/3243869-1-1@2x.png",
    spanText10: <>..to screen... <br />to smartphone</>,
    spanText11: "Patented Jump Screen Technology*",
    spanText12: "Move with your customer proactively from screen to screen.",
    frame13213139365Props: frame132131393656Data,
};


const frame1321314062212Data = {
    className: "",
};

const frame132131393653Data = {
    className: "frame-1321313936-15",
    frame13213140622Props: frame1321314062212Data,
};

const view1Data = {
    screens: "/img/screens-5@1x.png",
};

const location32Data = {
    spanText: "Location Name",
};

const location33Data = {
    spanText: "First floor",
    className: "location-14",
};

const location34Data = {
    spanText: "Second floor",
    className: "location-11-1",
};

const location35Data = {
    spanText: "QRideo",
    className: "location-11",
};

export const x4Page4Data = {
    x4Page_4: "/img/4-page-4@1x.png",
    group1359804: "/img/group-1359804-1@2x.png",
    spanText1: "How it works for you?",
    spanText2: "Manage calls and upload digital content",
    spanText3: "Web Panel",
    devicesAppleIphone1212Pro1: "/img/devices---apple---iphone-12---12-pro-1@360x.png",
    devicesAppleIphone1212Pro2: "/img/devices---apple---iphone-12---12-pro-2@360x.png",
    spanText4: "Locations",
    group78: "/img/group-78-3@2x.png",
    overlapGroup1: "/img/rectangle-9-3@2x.png",
    spanText5: "New York Tower",
    iconArrowDown: "/img/icon---arrow-down-14@2x.png",
    spanText6: "iOS or Android",
    spanText7: "Mobile device",
    spanText8: "Answer calls from different locations",
    frame1359707: "/img/frame-1359707-1@2x.png",
    bg1: "/img/bg1-1@1x.png",
    group1359639: "/img/group-1359639-1@2x.png",
    frame13213139365Props: frame132131393653Data,
    viewProps: view1Data,
    location31Props: location32Data,
    location32Props: location33Data,
    location33Props: location34Data,
    location34Props: location35Data,
};

const frame1321314062213Data = {
    className: "",
};

const frame132131393654Data = {
    frame13213140622Props: frame1321314062213Data,
};

const view2Data = {
    screens: "/img/screens-6@1x.png",
    className: "view-1",
};

export const x4Page5Data = {
    x4Page_5: "/img/4-page-5@1x.png",
    spanText1: "Brand new cutting edge features...",
    spanText2: "Voice-to-text translation",
    spanText3: "Up to 60 languages.",
    monitor: "/img/monitor-5@2x.png",
    rectangle4: "/img/rectangle-4-5@1x.png",
    overlapGroup: "/img/union-40@2x.png",
    spanText4: "🇺🇸",
    overlapGroup1: "/img/union-41@2x.png",
    spanText5: "🇰🇷",
    frame1091343: "/img/frame-1091343-1@2x.png",
    spanText6: "Access Control",
    spanText7: "Open doors/lift floors remotely.",
    group1359866: "/img/group-1359866-7@2x.png",
    devicesAppleIphone1212Pro: "/img/devices---apple---iphone-12---12-pro-3@360x.png",
    frame13213139365Props: frame132131393654Data,
    viewProps: view2Data,
};

const frame1321314062211Data = {
    className: "",
};

const frame132131393652Data = {
    frame13213140622Props: frame1321314062211Data,
};

const group6471Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather1Data = {
    x26C: "/img/26-c-11@2x.png",
    x1300: "/img/13-00-1@2x.png",
    group647Props: group6471Data,
};

const group6472Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather2Data = {
    x26C: "/img/26-c-11@2x.png",
    x1300: "/img/10-00-1@2x.png",
    className: "cardweather-2-1",
    group647Props: group6472Data,
};

const group6473Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const group6474Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather3Data = {
    x26C: "/img/26-c-12@2x.png",
    x1300: "/img/12-00-1@2x.png",
    className: "cardweather-2-2",
    group647Props: group6474Data,
};

const group6475Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather4Data = {
    x26C: "/img/26-c-12@2x.png",
    x1300: "/img/13-00-2@2x.png",
    className: "cardweather-2-3",
    group647Props: group6475Data,
};

const group6476Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather5Data = {
    x26C: "/img/26-c-8@2x.png",
    x1300: "/img/14-00-1@2x.png",
    className: "cardweather-2-4",
    group647Props: group6476Data,
};

const group6477Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather6Data = {
    x26C: "/img/29-c-1@2x.png",
    x1300: "/img/15-00-1@2x.png",
    className: "cardweather-2-2",
    group647Props: group6477Data,
};

const group6478Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather7Data = {
    x26C: "/img/26-c-11@2x.png",
    x1300: "/img/16-00-1@2x.png",
    className: "cardweather-2",
    group647Props: group6478Data,
};

const group6479Data = {
    union: "/img/ellipse-8-20@2x.png",
    group647: "/img/union-39@2x.png",
    className: "group-647-2",
};

const cardweather22Data = {
    x23C: "/img/23-c-1@2x.png",
    x1800: "/img/18-00-1@2x.png",
};

const group64710Data = {
    group647: "/img/ellipse-8-11@2x.png",
    union: "/img/union-28@2x.png",
    className: "group-647-1",
};

const cardweather23Data = {
    x23C: "/img/22-c-1@2x.png",
    x1800: "/img/19-00-1@2x.png",
    className: "cardweather-4",
};

const group64711Data = {
    group647: "/img/ellipse-8-11@2x.png",
    union: "/img/union-29@2x.png",
    className: "group-647-1",
};

const group64712Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather32Data = {
    x26C: "/img/26-c-8@2x.png",
    x2000: "/img/20-00-1@2x.png",
    group647Props: group64712Data,
};

const group64713Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather8Data = {
    x26C: "/img/26-c-11@2x.png",
    x1300: "/img/21-00-1@2x.png",
    className: "cardweather-2-2",
    group647Props: group64713Data,
};

const group64714Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather33Data = {
    x26C: "/img/26-c-11@2x.png",
    x2000: "/img/22-00-1@2x.png",
    className: "cardweather-5-2",
    group647Props: group64714Data,
};

const group64715Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather34Data = {
    x26C: "/img/26-c-11@2x.png",
    x2000: "/img/23-00-1@2x.png",
    className: "cardweather-5-3",
    group647Props: group64715Data,
};

const group64716Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const cardweather35Data = {
    x26C: "/img/26-c-12@2x.png",
    x2000: "/img/24-00-1@2x.png",
    className: "cardweather-5",
    group647Props: group64716Data,
};

const group64717Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const group64718Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const group64719Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

const group64720Data = {
    group647: "/img/ellipse-8-20@2x.png",
    union: "/img/union-39@2x.png",
};

export const x4Page6Data = {
    x4Page_6: "/img/4-page-6@1x.png",
    spanText1: "Designed for you to provide the best Customer Experience…",
    spanText2: "Add static or video signage, or upload pre-recorded video messages to automate repetitive tasks.",
    x26C: "/img/26-c-12@2x.png",
    ellipse101: "/img/ellipse-10-14@2x.png",
    x1100: "/img/11-00-1@2x.png",
    cardweather: "/img/rectangle-31-1@2x.png",
    x24C: "/img/24-c-1@2x.png",
    x1700: "/img/17-00-1@2x.png",
    subtract1: "/img/subtract-13@2x.png",
    subtract2: "/img/subtract-14@2x.png",
    today: "/img/today-1@2x.png",
    boston: "/img/boston-1@2x.png",
    sep12: "/img/sep--12-1@2x.png",
    nextForecast: "/img/next-forecast-1@2x.png",
    sep13: "/img/sep--13-1@2x.png",
    vector7: "/img/vector-9-1@2x.png",
    vector8: "/img/vector-9-1@2x.png",
    vector9: "/img/vector-9-1@2x.png",
    rectangle351: "/img/rectangle-35-1@2x.png",
    rectangle361: "/img/rectangle-36-2@2x.png",
    rectangle371: "/img/rectangle-40-2@2x.png",
    rectangle381: "/img/rectangle-40-2@2x.png",
    rectangle391: "/img/rectangle-39-2@2x.png",
    rectangle401: "/img/rectangle-40-2@2x.png",
    x21: "/img/21--1@2x.png",
    sep14: "/img/sep--14-1@2x.png",
    rectangle352: "/img/rectangle-36-2@2x.png",
    rectangle41: "/img/rectangle-39-2@2x.png",
    rectangle362: "/img/rectangle-36-2@2x.png",
    rectangle372: "/img/rectangle-40-2@2x.png",
    rectangle382: "/img/rectangle-40-2@2x.png",
    rectangle392: "/img/rectangle-39-2@2x.png",
    rectangle402: "/img/rectangle-40-2@2x.png",
    x22: "/img/22--1@2x.png",
    sep15: "/img/sep--15-1@2x.png",
    group675: "/img/group-675-1@2x.png",
    x34: "/img/34--1@2x.png",
    sep16: "/img/sep--16-1@2x.png",
    union: "/img/union-37@2x.png",
    x27: "/img/27--1@2x.png",
    sep17: "/img/sep--17-1@2x.png",
    ellipse102: "/img/ellipse-10-14@2x.png",
    x32: "/img/32--1@2x.png",
    monitor: "/img/monitor-4@2x.png",
    rectangle4: "/img/rectangle-4-4@1x.png",
    unsplashA19Ovaa2Rza: "/img/unsplash-a19ovaa2rza-1@2x.png",
    group1359857: "/img/group-1359857-1@2x.png",
    frame13213139365Props: frame132131393652Data,
    cardweather1Props: cardweather1Data,
    cardweather2Props: cardweather2Data,
    group6471Props: group6473Data,
    cardweather3Props: cardweather3Data,
    cardweather4Props: cardweather4Data,
    cardweather5Props: cardweather5Data,
    cardweather6Props: cardweather6Data,
    cardweather7Props: cardweather7Data,
    group6472Props: group6479Data,
    cardweather21Props: cardweather22Data,
    group6473Props: group64710Data,
    cardweather22Props: cardweather23Data,
    group6474Props: group64711Data,
    cardweather31Props: cardweather32Data,
    cardweather8Props: cardweather8Data,
    cardweather32Props: cardweather33Data,
    cardweather33Props: cardweather34Data,
    cardweather34Props: cardweather35Data,
    group6475Props: group64717Data,
    group6476Props: group64718Data,
    group6477Props: group64719Data,
    group6478Props: group64720Data,
};

const frame13213141322Data = {
    className: "frame-1321314135",
};

export const x4Page7Data = {
    x4Page_7: "/img/4-page-7-1@2x.png",
    frame1321314061: "/img/fill-24-18@2x.png",
    spanText1: "Intercoms",
    spanText2: "Video Concierge",
    spanText3: "Help As A Service",
    spanText4: "Contact Us",
    spanText5: "Get Started, Free",
    spanText6: "Basic",
    spanText7: "12 (or) 36 month subscription",
    spanText8: <>Basic Features <br />Tap/Voice Activation<br />Reactive Calling <br />Single Call Group<br />Single Static Digital Sign<br />PC Only <br />3 x Intercom Operators (not upgradable)</>,
    spanText9: "Advanced",
    spanText10: "12 (or) 36 month subscription",
    spanText11: <>Advanced Features <br />Tap/Voice Activation<br />Proactive &amp; Reactive Calling <br />Jump Screen / Jump to Phone<br />Multiple Call Groups Available<br />Multiple Static &amp; Video Digital Messages/Signs<br />PC / iPad <br />3 x Intercom Operators (upgradable)<br />Access Control Connectors (Coming Soon)<br />Voice to Text Translation (upgradable)<br />Сonnect with Inception/Integriti* (coming soon)</>,
    frame1321314132Props: frame13213141322Data,
};

const iconScreen7Data = {
    src: "/img/shape-11@2x.png",
};

const iconScreen8Data = {
    src: "/img/shape-12@2x.png",
};

const iconScreen9Data = {
    src: "/img/shape-13@2x.png",
};

const info3Data = {
    name: "/img/name-12@2x.png",
};

const info4Data = {
    name: "/img/name-13@2x.png",
};

const location5Data = {
    spanText: "Location Name",
};

const location6Data = {
    spanText: "First floor",
    className: "location-6",
};

const location7Data = {
    spanText: "Second floor",
    className: "location-4-2",
};

const location8Data = {
    spanText: "QRideo",
    className: "location-4",
};

const iconElectronSay7Data = {
    src: "/img/shape-10@2x.png",
    className: "icon-chat-1-1",
};

const notiification5Data = {
    notiification: "/img/oval-2-2@2x.png",
};

const header3Data = {
    fill23: "/img/fill-23-6@2x.png",
    className: "header-2",
    iconElectronSayProps: iconElectronSay7Data,
    notiificationProps: notiification5Data,
};

const iconElectronSay8Data = {
    src: "/img/shape-10@2x.png",
    className: "icon-chat-1",
};

const notiification6Data = {
    notiification: "/img/oval-2-2@2x.png",
};

const header4Data = {
    fill23: "/img/fill-23-1@2x.png",
    className: "header-3",
    iconElectronSayProps: iconElectronSay8Data,
    notiificationProps: notiification6Data,
};



const group13598954Data = {
    group1359895: "/img/group-1359894-1@2x.png",
    group1359892: "/img/group-1359892-1@2x.png",
};

const frame13598552Data = {
    className: "group-1359855",
    group1359895Props: group13598954Data,
};

export const x4Page8Data = {
    x4Page_8: "/img/4-page-8-1@2x.png",
    spanText1: "Web, mobile or tablet device",
    group1359868: "/img/macbook-pro-02-1@2x.png",
    screens: "/img/screens-7@2x.png",
    spanText2: "Agenda",
    spanText3: "New York Tower",
    spanText4: "3 screens",
    spanText5: "ONLINE",
    iconMore1: "/img/icon---more-5@2x.png",
    name1: "/img/name-11@2x.png",
    iconFaceRecognitiont1: "/img/icon---face-recognitiont-4@2x.png",
    iconAudio1: "/img/union-42@2x.png",
    iconVideo1: "/img/icon---video-6@2x.png",
    spanText6: "4:3",
    spanText7: "BUSY",
    iconMore2: "/img/icon---more-5@2x.png",
    name2: "/img/name-14@2x.png",
    iconAudio2: "/img/union-17@2x.png",
    iconVideo2: "/img/icon---video-6@2x.png",
    spanText8: "4:3",
    spanText9: "ONLINE",
    iconMore3: "/img/icon---more-5@2x.png",
    name3: "/img/name-15@2x.png",
    iconFaceRecognitiont2: "/img/icon---face-recognitiont-4@2x.png",
    iconAudio3: "/img/union-17@2x.png",
    iconVideo3: "/img/icon---video-6@2x.png",
    spanText10: "4:3",
    hideButtons: "/img/hide-buttons-3@2x.png",
    spanText11: "Locations",
    group78: "/img/group-78-2@2x.png",
    overlapGroup1: "/img/rectangle-9-1@2x.png",
    spanText12: "New York Tower",
    iconArrowDown: "/img/icon---arrow-down-4@2x.png",
    flatIpad: "/img/flat-ipad-4@2x.png",
    black: "/img/black-3@2x.png",
    group81: "/img/group-8-6@2x.png",
    rectangle201: "/img/rectangle-20-2@2x.png",
    group101: "/img/group-10-2@2x.png",
    group82: "/img/group-8-7@2x.png",
    rectangle202: "/img/rectangle-20-2@2x.png",
    subtract: "/img/subtract-6@2x.png",
    group102: "/img/group-10-3@2x.png",
    group11: "/img/group-11-4@2x.png",
    group9: "/img/group-9-2@2x.png",
    spanText13: "You:",
    spanText14: "Yes, sure, how can I help? I can see you’re scanning the QR code from Parking Level 4.",
    spanText15: "Client:",
    spanText16: "Yes, I’m one of the staff at Acme Inc.",
    spanText17: "Teleportivity Cloud",
    group1359898: "/img/group-1359898-3@2x.png",
    frame13213139365Props: frame132131393657Data,
    iconElectronSayProps: iconElectronSay6Data,
    iconScreen1Props: iconScreen7Data,
    iconScreen2Props: iconScreen8Data,
    iconScreen3Props: iconScreen9Data,
    info1Props: info3Data,
    info2Props: info4Data,
    location1Props: location5Data,
    location2Props: location6Data,
    location3Props: location7Data,
    location4Props: location8Data,
    header1Props: header3Data,
    header2Props: header4Data,
    frame1359855Props: frame13598552Data,
};