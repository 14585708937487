import classNames from 'classnames';
import { cloneElement, isValidElement } from 'react';

const mergeProps = (oldProps, extraProps) => {
    return {...oldProps, ...extraProps, className: classNames(oldProps.className, extraProps.className)}
}

const appendProps = (reactElement, props) => {
    if (reactElement.join) {
        return reactElement.reduce((acc, el) => {
            if (isValidElement(el)) return [...acc, cloneElement(el, mergeProps(el.props, props))]
        }, [])
    }
    return cloneElement(reactElement, mergeProps(reactElement.props, props))
}

export default appendProps