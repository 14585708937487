import React from 'react';

const ChromeIcon = (props) => <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5605_54915)">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.9573 25.9888C17.1468 25.9858 15.4113 25.2652 14.1312 23.9848C12.8511 22.7044 12.1309 20.9688 12.1284 19.1583C12.1305 17.3472 12.8507 15.6109 14.1312 14.3301C15.4117 13.0494 17.1478 12.3287 18.9589 12.3262C20.7702 12.3283 22.5067 13.0487 23.7876 14.3296C25.0684 15.6104 25.7888 17.3469 25.7909 19.1583C25.7884 20.9693 25.0678 22.7055 23.787 23.9859C22.5062 25.2664 20.7699 25.9867 18.9589 25.9888H18.9573Z" fill="#4285F4"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21.8358 27.2037C20.4109 27.7208 18.8759 27.8575 17.3821 27.6004C15.8883 27.3433 14.4874 26.7012 13.3174 25.7375C12.0561 24.6972 11.1134 23.3226 10.5973 21.7713L10.5941 21.7618L3.06534 8.72461C0.365411 12.8559 -0.604568 17.882 0.364171 22.7213C0.839886 25.166 1.79572 27.4921 3.17635 29.5649C4.55698 31.6378 6.335 33.4162 8.4075 34.7974C10.5835 36.2602 13.0411 37.2525 15.6228 37.7107L21.8358 27.2053V27.2037Z" fill="#4AAE48"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M36.0367 10.6198C34.6125 7.75471 32.4923 5.29266 29.8703 3.4591C27.2482 1.62553 24.2081 0.479028 21.0282 0.124573C17.8484 -0.229882 14.6304 0.219033 11.669 1.43022C8.70751 2.6414 6.09722 4.57615 4.07709 7.05731L10.4358 18.071C10.6975 16.0318 11.6861 14.1555 13.2201 12.7866C14.7541 11.4178 16.7305 10.6485 18.7863 10.6198H36.0367Z" fill="#EA3939"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.8828 37.9999C23.6487 37.9999 28.2103 36.2123 31.7237 32.9681C35.2506 29.72 37.4105 25.2524 37.7657 20.4708C37.9702 17.7026 37.5681 14.923 36.5877 12.3262H24.149C25.1853 13.1459 26.0217 14.1905 26.5949 15.3809C27.1682 16.5714 27.4634 17.8766 27.4582 19.1978C27.4456 21.1405 26.7872 23.0238 25.5867 24.5511L17.6573 37.9619C18.0658 37.9873 18.4775 37.9999 18.8828 37.9999Z" fill="#FED14B"/>
    </g>
    <defs>
    <clipPath id="clip0_5605_54915">
    <rect width="38" height="38" fill="white"/>
    </clipPath>
    </defs>
</svg>

export default ChromeIcon