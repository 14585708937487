import React from "react";
import "./Frame1321313978.sass";

function Frame1321313978(props) {
  const { screenshot20220225At16461, className } = props;

  return (
    <div className={`frame-1321313978 ${className || ""}`}>
      <img className="screenshot-2022-02-25-at-1646-1" src={screenshot20220225At16461} />
      <p className="scan-the-code-to-see montserrat-normal-regent-gray-14px">
        <span className="span3afx4 montserrat-normal-regent-gray-14px">
          Scan the code to see how QR Video Intercom can reimagine your commercial&nbsp;&nbsp;sites
        </span>
      </p>
    </div>
  );
}

export default Frame1321313978;
