import React from "react";
import "./Frame1321313918.sass";

function Frame1321313918(props) {
  const { spanText1, spanText2, className } = props;

  return (
    <div className={`frame-1321313918 ${className || ""}`}>
      <div className="saying-hello-to-futu montserrat-bold-steel-gray-36px">
        <span className="span-29 montserrat-bold-steel-gray-36px">{spanText1}</span>
      </div>
      <div className="so-say-goodbye-to-bu montserrat-normal-regent-gray-18px">
        <span className="span-29 montserrat-normal-regent-gray-18px">{spanText2}</span>
      </div>
    </div>
  );
}

export default Frame1321313918;
