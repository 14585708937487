import React from 'react';


const button32Data = {
    className: "button-49",
};

const frame132131406216Data = {
    className: "frame-1321314062-15",
    buttonProps: button32Data,
};

const frame132131393625Data = {
    className: "frame-1321313936-4",
    frame1321314062Props: frame132131406216Data,
};

const frame13213139081Data = {
    spanText1: "For them",
    spanText2: "Visitors simply  scan the QR Video Intercom code (no app required)",
};

const bilockFill3Data = {
    src: "/img/group-87@2x.png",
    className: "bilock-fill-3",
};

const frame13213139082Data = {
    spanText1: "For you",
    spanText2: <>-   	You receive a PUSH notification - Phone/Tablet/PC<br />-   	You’ll see which QR entry has been scanned<br />-   	Answer the call with the click of a button<br />-   	Unlock  doors and elevators whilst on the call</>,
    className: "frame-1321313908-3",
};

const mobileMenu36Data = {
    fill24: "/img/fill-24-18@2x.png",
    ciHamburger: "/img/ci-hamburger-18@2x.png",
    className: "mobile-menu-35",
};

export const x2Page6Data = {
    x2Page_6: "/img/2-page-6-1@2x.png",
    spanText1: "How it works?",
    overlapGroup4: "/img/iphone-11-pro---x---7-1@1x.png",
    frame1359979: "/img/qr-code-1@2x.png",
    group1359765: "/img/group-1359754-1@2x.png",
    fill24: "/img/fill-24-36@2x.png",
    frame13599801: "/img/group-1359979-1@2x.png",
    group1359755: "/img/group-89-1@1x.png",
    wcVideoCallWithChat: "/img/wc-video-call-with-chat-1@2x.png",
    spanText2: "9:41",
    cellularConnection1: "/img/cellular-connection-11@2x.png",
    wifi1: "/img/wifi-13@2x.png",
    battery1: "/img/battery-13@2x.png",
    overlapGroup1: "/img/teleportivity-com-1@2x.png",
    vector2: "/img/vector-12@2x.png",
    group1359868: "/img/frame-1359980-1@2x.png",
    frame13599802: "/img/screens-11@1x.png",
    group1359866: "/img/group-1359866-9@2x.png",
    incomingCall: "/img/incoming-call-2@2x.png",
    frame: "/img/frame-17@2x.png",
    intercomMobileDoorOnly: "/img/intercom---mobile---door-only-11@1x.png",
    spanText3: "9:41",
    cellularConnection2: "/img/cellular-connection-12@2x.png",
    wifi2: "/img/wifi-14@2x.png",
    battery2: "/img/battery-14@2x.png",
    screenShine: "/img/screen-shine-20@2x.png",
    notch: "/img/notch-shape-17@2x.png",
    camera: "/img/camera-17@2x.png",
    x2Page_7: "/img/2-page-7@1x.png",
    x2Page_7_Mobile: "/img/2-page-7-mobile-1@2x.png",
    spanText4: <>Don’t just say hello to visitors. <br/> Say hello to the future of connection.</>,
    spanText5: "Calling QR Video Intercom just an intercom is like calling the iPhone just a phone. Sure, it can connect you in all the familiar ways. But it also integrates with popular third parties; creating smart spaces, smart buildings and smart points wherever you want them.",
    spanText6: "Navigation Panel",
    line311: "/img/line-31-1@2x.png",
    iphonexsSpaceGrayscreen1: "/img/iphonexs---space-grayscreen-1-1@2x.png",
    spanText7: <>Link to Google Maps<br />&amp; Google Directions</>,
    line312: "/img/line-31-2@2x.png",
    iphonexsSpaceGraynewfrtre1: "/img/iphonexs---space-graynewfrtre-1-1@2x.png",
    spanText8: "Optional QR Lock",
    line313: "/img/line-31-3@2x.png",
    iphonexsSpaceGraynewnew2Frtre1: "/img/iphonexs---space-graynewnew-2frtre-1-1@2x.png",
    spanText9: <>Pre-recorded videos<br />Youtube / MP4</>,
    line314: "/img/line-31-6@2x.png",
    iphonexsSpaceGraynew2: "/img/iphonexs---space-graynew-2-1@2x.png",
    spanText10: "Documents & Images",
    line315: "/img/line-31-5@2x.png",
    iphonexsSpaceGraynew8: "/img/iphonexs---space-graynew-8-1@1x.png",
    spanText11: "Intercom system",
    line316: "/img/line-31-6@2x.png",
    iphoneVer11: "/img/iphone---ver-1-1-1@2x.png",
    spanText12: "Multiple User Calling Groups",
    line317: "/img/line-31-7@2x.png",
    iphonexsSpaceGraynew1: "/img/iphonexs---space-graynew-1-1@2x.png",
    spanText13: "Call Queuing System",
    line318: "/img/line-31-8@2x.png",
    group401: "/img/group-40-1-1@2x.png",
    spanText14: "Unlock the door right from the call",
    line319: "/img/line-31-9@2x.png",
    spanText15: "Unlock the door from the app",
    line3110: "/img/line-31-11@2x.png",
    intercomMobile: "/img/intercom--mobile-1@1x.png",
    group1359750: "/img/group-1359750-1@2x.png",
    group1359749: "/img/intercom--mobile-2@1x.png",
    spanText16: <>Video call<br />Flip Camera / Transfer Call / Augmented Reality</>,
    line3111: "/img/line-31-10@2x.png",
    frame13213139362Props: frame132131393625Data,
    frame13213139081Props: frame13213139081Data,
    bilockFillProps: bilockFill3Data,
    frame13213139082Props: frame13213139082Data,
    mobileMenuProps: mobileMenu36Data,
};