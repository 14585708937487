import React from "react";
import { Link, useLocation } from 'react-router-dom';
import {paths} from "../../const/paths";
import './LandingHeader.sass'

const NavElement = (props) => {
  const { path, alias, isActive } = props;
  return <a href={path}>
    <div className="header-menuelement">
      <span className="montserrat-normal-fiord-14px">{alias}</span>
    </div>
    </a>
}

const NavMenu = (props) => {
  const { currentPath } = props;
    return Object.entries(paths).map(([path, alias]) => 
      <NavElement
        path={`/${path}`}
        alias={alias}
        key={path}
        isActive={currentPath === `/${path}`}
      />
    )
}

const LandingHeader = (props) => {
  const { pathname } = useLocation();
  return (
    <div className="header-wrapper">
      <NavMenu currentPath={pathname}/>
    </div>
  )
}

export default LandingHeader;