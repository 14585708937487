import React from "react";
import "./Frame1091343.sass";

function Frame1091343(props) {
  const { rectangle4, className } = props;

  return (
    <div className={`frame-1091343 ${className || ""}`}>
      <div className="overlap-group-3">
        <div className="rectangle-363"></div>
        <img className="monitor" src="/img/monitor-2@2x.png" />
        <img className="rectangle-4" src={rectangle4} />
      </div>
    </div>
  );
}

export default Frame1091343;
