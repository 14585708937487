import React from 'react';
import { cardBackgroundColors } from '../../components/Card';
import MulticardScreen from '../../components/ContentScreens/MulticardScreen';
import X3Page1 from '../../components/FonecomPage/X3Page1';
import X3Page7 from '../../components/FonecomPage/X3Page7';
import GenericScreen from '../../components/GenericScreen';
import SplashScreen from '../../components/ContentScreens/SplashScreen';
import './FonecomPage.sass'
import OutdoorFeatures from './OutdoorFeatures';

const FonecomPage = (props) => {
    return <>
    <SplashScreen
        title={<>FONECOM<br/>Outdoor Display</>}
        text={<div>Make an unforgettable first impression, from anywhere</div>}
        picture='/img/outdoor-full-display.png'
        className='outdoor-splash'
    />
    <MulticardScreen
        cards={
            [
                {
                    bgColor: cardBackgroundColors.none,
                    background: '/img/outdoor1.png',
                },
                {
                    title: <>
                        Commercial<br/>
                        Keep every site in sight
                    </>,
                    subtitle: <>Turn every commercial site into a fully connected smart building with FONECOM Intercom Screens. 
                    And without hardwiring, cabling or expensive installation, it’s a quick step for a huge leap in productivity, efficiency and engagement.  </>
                }   
            ]
        }
    />

    <MulticardScreen
        cards={[
            {
                title: <>Multi-Story Residential<br/>Make apartments feel like more than a number</>,
                subtitle: 'Our FONECOM outdoor intercoms offer multi-residential apartment blocks new heights of connectivity'
            },
            {
                background: '/img/outdoor2.png'
            }
        ]}
    />

    <MulticardScreen
        cards={[
            {
                background: '/img/outdoor3.png', 
            },
            {
                title: <>Build on the cloud<br/>Designed for the future</>,
                subtitle: `Other intercoms are contained within their own clunky hardware. But not ours. Our FONECOM intercoms are built from the cloud up, ensuring not just a new standard of connect-tech, but also a product with longevity at its heart, designed to remain at the cutting edge, both now and in the future.`,
            }
        ]}
    />

    <MulticardScreen
        cards={[
            {
                title: 'An all access pass in your pocket',
                subtitle:  `Simple web relay integration lets you open doors for your customers, unlock gates for deliveries, call elevators for guests, see where every call 
                and connection originates from, and much more - all with the touch of a button. This is oversight and control as you’ve never experienced.
                `,
            },
            {
                background: '/img/outdoor4.png',
            }
        ]}
    />

    <MulticardScreen
        cards={[
            {
                background: '/img/outdoor5.png'
            },
            {
                title: `Where digital solutions meet the human touch`,
                subtitle: <>Teleportivity is a globally awarded tech innovator that puts authentic human connection at the heart of seamless, efficient and scalable digital experiences.<br/>
                Trusted by brands, Teleportivity’s cloud-based communication solutions are reimagining connection at every stage of your customer journey.</>
            }
        ]}
    />
    <X3Page7/>
    <OutdoorFeatures/>
    </>
}

export default FonecomPage