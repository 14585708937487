import React from "react";
import "./Frame1321313911.sass";

function Frame1321313911(props) {
  const { className } = props;

  return (
    <div className={`frame-1321313911 ${className || ""}`}>
      <div className="free montserrat-semi-bold-white-18px">
        <span className="spant9mh1k montserrat-semi-bold-white-18px">FREE</span>
      </div>
    </div>
  );
}

export default Frame1321313911;
