import React from "react";
import Button from "../Button";
import "./Frame13213140622.sass";

function Frame13213140622(props) {
  const { className } = props;

  return (
    <div className={`frame-1321314062-17 ${className || ""}`}>
      <Button />
      <div className="button-51">
        <div className="feedbacks-53 valign-text-middle montserrat-medium-white-16px">
          <span>
            <span className="span6vrfv montserrat-medium-white-16px">Request Demo</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Frame13213140622;
