import "./App.sass";
import './globals.sass';
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ContentContainer from "./containers/ContentContainer";
import { DetectMobileContextProvider } from "react-mobile-detect";

function App() {
  return (
    <DetectMobileContextProvider>
    <Router>
          <ContentContainer/>
      </Router>
    </DetectMobileContextProvider>
  );
}

export default App;