import React from 'react';
import StaffCard from '../../components/StaffCard';
import { staff } from './values';

import './Contacts.sass'

const ContactsPage = (props) => {
    return <>
        <div className='content-screen'>
        <h2 className='section-title'>Senior Leadership Team</h2>
        <div className='container-contacts-staff'>
            {
                staff.map((member) => 
                    <StaffCard key={member.name} {...member}/>
                )
            }
        </div>
        </div>
    </>
}

export default ContactsPage