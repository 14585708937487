import React from "react";
import Frame13213139364 from "../Frame13213139364";
import "./X5Page5.sass";

function X5Page5(props) {
  const {
    x5Page_5,
    group1359843,
    rectangle,
    ellipse88,
    spanText1,
    spanText2,
    spanText3,
    frame13213139851,
    group13597871,
    spanText4,
    frame13213139852,
    group13597872,
    spanText5,
    frame13213139853,
    group13597873,
    overlapGroup,
    spanText6,
    frame13213139364Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x5-page5 screen">
        <div className="frame-1321313897-1">
          <div className="overlap-group2-13">
            <img className="group-1359843-1" src={group1359843} />
            <img className="rectangle-1" src={rectangle} />
            <img className="ellipse-88-2" src={ellipse88} />
            <div className="frame-1321314151">
              <div className="frame-1321313991">
                <div className="help-as-a-service-28 montserrat-bold-white-36px">
                  <span className="montserrat-bold-white-36px">{spanText1}</span>
                </div>
                <div className="create-your-own-as montserrat-normal-white-18px">
                  <span className="montserrat-normal-white-18px">{spanText2}</span>
                </div>
              </div>
              <div className="group-1359967">
                <div className="group-container-13">
                  <div className="group-1359854">
                    <div className="av-live-help-desk montserrat-normal-white-18px">
                      <span className="montserrat-normal-white-18px">{spanText3}</span>
                    </div>
                    <img className="frame-1321313985" src={frame13213139851} />
                    <img className="group-1359787-2" src={group13597871} />
                  </div>
                  <div className="group-1359851">
                    <div className="av-live-help-desk-1 montserrat-normal-white-18px">
                      <span className="montserrat-normal-white-18px">{spanText4}</span>
                    </div>
                    <img className="frame-1321313985" src={frame13213139852} />
                    <img className="group-1359787-2" src={group13597872} />
                  </div>
                </div>
                <div className="group-1359852">
                  <div className="av-live-help-desk-2 montserrat-normal-white-18px">
                    <span className="montserrat-normal-white-18px">{spanText5}</span>
                  </div>
                  <img className="frame-1321313985-1" src={frame13213139853} />
                  <img className="group-1359787-2" src={group13597873} />
                </div>
                <div className="frame-1321313985-2">
                  <div className="overlap-group-58" style={{ backgroundImage: `url(${overlapGroup})` }}>
                    <div className="your-own-as-a-service-offering montserrat-normal-white-18px">
                      <span className="montserrat-normal-white-18px">{spanText6}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default X5Page5;
