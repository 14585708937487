import React from 'react';
import assets from '../../const/assets';
import { Link } from 'react-router-dom';
import './Logo'

const Logo = (props) => {
    const { path } = props;
    return <div className='component-logo'>
        <a href="/">
            <img src={path}/>
        </a>
    </div>
}

Logo.defaultProps = {
    path: assets.logo
}

export default Logo