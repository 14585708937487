import React from "react";
import Frame13598842 from "../Frame13598842";
import Frame1321314062 from "../Frame1321314062";
import RectangleButton from "../RectangleButton";
import RectangleButton2 from "../RectangleButton2";
import "./X1Page.sass";
import Screen from "../Screen";
import AdaptiveBlock from "../TwoColBlock";
import Card from "../Card";
import classNames from "classnames";
import { Link } from "react-router-dom";

const images = {
  qrideo: '/img/intercoms_qrideosplash.png',
  outdoor: '/img/outdoor-full-display.png'
}

const FeatureCard = (props) => {
  const {
      title,
      description,
      moreLink,
      background,
      subtitle,
      className
  } = props;
  return <Card
        title={title}
        className={classNames('feature-card', className)}
        background={background}
        // style={{
        //   backgroundImage:`url(${imageLink})`,
        //   backgroundRepeat: 'no-repeat',
        //   height: '30rem'
        // }}
      >
            <div className='feature-text'>
                <b>{subtitle}</b>
            </div>
          <div className='feature-text'>
              {description}
          </div>
          <a href={moreLink}>
              <div className='feature-link'>
                  Discover >
              </div>
          </a>
      </Card>
}

function X1Page(props) {
  const {
    spanText1,
    spanText2,
    spanText3,
  } = props;

  return (
    <div className="content-screen page-sized ">
      <Screen className={'column-layout'}>
        <div className="screen-title">{spanText1}</div>
        <div className="screen-content">{spanText2}</div>
        <div className="screen-content"><b>{spanText3}</b></div>
      </Screen>
      <Screen>
        <AdaptiveBlock
          colums={2}
        >
          <FeatureCard
            title='QR Video-Voice-Text'
            subtitle="QR Video Intercom"
            description='A new world of connection is just a scan away'
            moreLink={'/qrvideo'}
            background={images.qrideo}
            className={'no-bottom-borders qrideo-image'}
            flexSize
          />
          <FeatureCard
            title='Outdoor Display'
            subtitle='Outdoor Display'
            description='Make an unforgettable first impression, from anywhere'
            moreLink={'/outdoor'}
            background={images.outdoor}
            className={'no-bottom-borders outdoor-image'}
            flexSize
          />
        </AdaptiveBlock>
      </Screen>
    </div>
  );
}

export default X1Page;
