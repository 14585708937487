import React from "react";
import "./Frame1321313914.sass";

function Frame1321313914(props) {
  const { group } = props;

  return (
    <div className="frame-1321313914-4">
      <img className="group-23" src={group} />
      <div className="voice montserrat-normal-regent-gray-18px">
        <span className="montserrat-normal-regent-gray-18px">Voice</span>
      </div>
    </div>
  );
}

export default Frame1321313914;
