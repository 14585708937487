import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import {paths} from '../../../const/paths';
import { mobileOnlyClass } from '../../../const/styling';
import Button from '../../Button';
import Card, { cardBackgroundColors } from '../../Card';
import MulticardScreen from '../../ContentScreens/MulticardScreen';
import Image from '../../Image';
import './FeatureScreen.sass'

const featureImages = {
    intercoms: '/img/group-1359886-1@1x.png',
    video: '/img/monitor.png',
    haas: '/img/frame-1359978-1@2x.png'

}

const FeatureScreen = (props) => {
    return <>
    <MulticardScreen
        id='discover'
        className="discover-features-screen"
        cards = {[
            {
                title: 'Intercoms',
                subtitle: 'Redefine what your intercom is capable of with a new level of accessibility, functionality and ease.',
                callToAction: { link: '/intercoms'},
                className: 'feature-preview-card',
                attachment: <Image
                    src={featureImages.intercoms}
                    className={'feature-image intercoms'}
                />
            },
            {
                title: 'Video Concierge',
                subtitle: 'No more staff shortages. No more unattended front desks. Just unparalleled service from anywhere to anywhere.',
                callToAction: { link: '/videoconcierge' },
                className: 'feature-preview-card',
                attachment: <Image
                    src={featureImages.video}
                    className={'feature-image video'}
                />
            },
            {
                title: 'Help-as-a-Service',
                subtitle: 'Whatever your requirements, support your staff and customers across thousands of access points with ease.',
                callToAction: { link: '/haas'},
                className: 'feature-preview-card',
                attachment: <Image
                    src={featureImages.haas}
                    className={'feature-image haas'}
                />
            },
            {
                bgColor: 'none',
                title: 'Reimagine your connection today',
                subtitle: <>
                    <span>Get started with us, when ready, contact us to put you in touch with one of our techology partners.</span>
                    <span className={mobileOnlyClass}><a>Case Studies</a> / <a> Use Cases</a></span>
                    </>,
                className: 'column-card',
                children: <div className='block-actions'>
                <Button
                    title={'Find Authorised Dealer'}
                    href={'/contact'}
                    />
                <Button
                    title={'Contact Teleportivity'}
                    color='blue'
                    href={'/contact'}
                 />
                </div>
            },
            {
                bgColor: cardBackgroundColors.none,
                className: 'award-logos',
                attachment:
                <>
                <Image src={"/img/frame-1321313946-2@2x.png"}/>
                <Image src={"/img/frame-1321313947-2@2x.png"}/>
                <Image src={"/img/frame-1321313948-2@2x.png"}/>
                <Image src={"/img/frame-1321313949-2@2x.png"}/>
                <Image src={"/img/frame-1321313950-2@2x.png"}/>
                <Image src={"/img/frame-1321313951-2@2x.png"}/>
                </>
            }
        ]}
    />
    </>
}

export default FeatureScreen