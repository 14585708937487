import React from 'react';
import MulticardScreen from '../../components/ContentScreens/MulticardScreen';
import Image from '../../components/Image';
import './Intercoms.sass'

const images = {
    qrideo: '/img/intercoms_qrideosplash.png',
    outdoor: '/img/outdoor-full-display.png'
  }

const IntercomsPage = (props) => {
    return <MulticardScreen
        title={'The intercom reimagined'}
        text={
            <div>
            <span>
                The way we communicate and run our businesses is evolving.
                <br/>
                But the Intercom – that crucial first point of connection – isn’t.<br/>
            </span>
            <span>
                <b>
                    Let’s change that.
                </b>
            </span>
            </div>
        }
        cards={[
            {
                title: 'QR Video-Voice-Text',
                className: 'no-bottom-corners intercom-card',
                subtitle: 'Qr Video Intercom',
                text: 'A new world of connection is just a scan away',
                callToAction: {
                        label: "Discover >",
                        link: '/qrvideo'
                    },
                attachment: <Image className='qrideo-image' src={images.qrideo}/>
            },
            {
                title: 'Outdoor Display',
                subtitle: 'Outdoor Display',
                text: 'Make an unforgettable first impression, from anywhere',
                className: 'no-bottom-corners intercom-card',
                callToAction: {
                        label: "Discover >",
                        link: '/outdoor'
                },
                attachment: <Image className='outdoor-image' src={images.outdoor}/>
            }
        ]}
    />
}

export default IntercomsPage