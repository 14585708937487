import React from "react";
import "./RectangleButton.sass";

function RectangleButton(props) {
  const { rectangleButton, fontistoKey, className } = props;

  return (
    <div className={`rectangle-button-3 ${className || ""}`} style={{ backgroundImage: `url(${rectangleButton})` }}>
      <img className="fontistokey" src={fontistoKey} />
    </div>
  );
}

export default RectangleButton;
