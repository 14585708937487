export const staff = [
    {
        name: 'Adam Gottlieb',
        job: ['Founder', 'CEO/CTO'],
        location: ['Sydney', 'San Francisco'],
    },
    {
        name: 'David Borg',
        job: ['Co-Founder', 'Chief Growth Officer'],
        location: ['Sydney', 'San Francisco']
    },
    {
        name: 'Mark Williams',
        job: 'CFO',
        location: 'Sydney'
    },
    {
        name: 'Josh Cohen',
        job: 'VP Client Acquisition',
        location: ['Sydney', 'San Francisco']        
    },
    {
        name: 'Jon Austin',
        job: 'Chief Creative Officer',
        location: 'New Zealand'
    },
    {
        name: 'Gordon Feller',
        job: 'VP of US Projects',
        location: 'San Francisco'
    },
    {
        name: 'Peter Savaris',
        job: 'Technical Support',
        location: 'Sydney'
    },
    {
        name: 'Natasha Blackburn',
        job: 'VP Customer Success',
        location: 'Sydney'
    },
    {
        name: 'Michael Dennis',
        job: ['US Advisor', 'Representative'],
        location: 'San Francisco'
    },
    {
        name: 'Nati Harpaz',
        job: 'Advisory Board Member',
        location: 'Sydney'
    }
]