import React from "react";
import Frame13598842 from "../Frame13598842";
import Frame1321314062 from "../Frame1321314062";
import "./Frame13213139362.sass";

function Frame13213139362(props) {
  const { className, frame1321314062Props } = props;

  return (
    <div className={`frame-1321313936-1 ${className || ""}`}>
      <img className="frame-1321314061-3" src="/img/fill-24-18@2x.png" />
      <Frame13598842 />
      <Frame1321314062 className={frame1321314062Props.className} buttonProps={frame1321314062Props.buttonProps} />
    </div>
  );
}

export default Frame13213139362;
