import React, { useEffect } from 'react';
import { BrowserView } from 'react-mobile-detect';
import { useLocation } from 'react-router-dom';
import HomePage from '../../components/HomePage';
import X1Page from '../../components/X1Page';
import {paths} from '../../const/paths';
import ContactsPage from '../../pages/Contacts';
import HaasPage from '../../pages/Haas';
import QrideoPage from '../../pages/Qrideo';
import VideoConciergePage from '../../pages/VideoConcierge';
import FooterContainer from '../FooterContainer';
import HeaderContainer from '../HeaderContainer';
import IntercomsPage from '../../pages/Intercom';
import FonecomPage from '../../pages/Fonecom';
import interceptAnchorScroll from '../../helpers/interceptAnchorScroll';

const ContentRouter = (props) => {
    const { page } = props;
    switch (page) {
        case 'qrvideo':
            return <QrideoPage/>
        case 'outdoor':
            return <FonecomPage/>
        case 'intercoms':
            return <IntercomsPage/>
        case 'videoconcierge':
            return <VideoConciergePage/>
        case 'haas':
            return <HaasPage/>
        case 'contact':
            return <ContactsPage/>
        case 'about':
            return <ContactsPage/>
        default:
            return <HomePage/>
    }
}


const ContentContainer = (props) => {
    const { pathname } = useLocation();
    const [, pageName ] = pathname.split('/');

    useEffect(() => {
        const runOnUnmount = interceptAnchorScroll();
        return () => {
            runOnUnmount()
        }
    }, [])
    return <>
        <HeaderContainer/>
        <div className={`container-content ${pageName || 'home'}`}>
            <ContentRouter page={pageName}/>
        </div>
        <FooterContainer/>
    </>
}

export default ContentContainer