import React from "react";
import Frame13213139362 from "../../Frame13213139362";
import SystemBarWhite from "../../SystemBarWhite";
import Hardware from "../../Hardware";
import Frame1321313918 from "../../Frame1321313918";
import "./X2Page3.sass";
import Screen from "../../Screen";
import AdaptiveBlock from "../../TwoColBlock";
import Card from "../../Card";
import { x2Page3Data } from "./values";

const image = '/img/fonecom3visual.png'

function X2Page3(props) {
  const {
    x2Page_3,
    frame1359979,
    frame,
    intercomMobileDoorOnly,
    screenShine,
    screenshot20220225At16441,
    spanText,
    frame13213139362Props,
    frame1321313918Props,
  } = x2Page3Data;


  return (
    <div className="content-screen page-sized">
      <Screen>
        <AdaptiveBlock
          columns={2}
          className={'align-top'}
          >
            <img src={image} className='contentitem'/>
            <Card
              title={frame1321313918Props.spanText1}
              flexSize
            >
              <div className='block-description'>
                  <div className="description">{frame1321313918Props.spanText2}</div>
              </div>
              <div className="frame-1321313977-2">
                <img className="screenshot-2022-02-25-at-1644-1" src={screenshot20220225At16441} />
                <p className="scan-the-code-to-see-3 montserrat-normal-regent-gray-14px">
                  <span className="montserrat-normal-regent-gray-14px">{spanText}</span>
                </p>
              </div>
            </Card>
        </AdaptiveBlock>
        </Screen>
    </div>
  );
}

export default X2Page3;
