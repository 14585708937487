import React from "react";
import Frame1321314062 from "../Frame1321314062";
import "./X5Page7.sass";

function X5Page7(props) {
  const {
    x5Page_7,
    frame1359972,
    spanText1,
    spanText2,
    spanText3,
    qr,
    frame1359971,
    frame1321314061,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    frame1321314062Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x5-page7 screen">
        <div className="overlap-group1-38">
          <img className="frame-1359972" src={frame1359972} />
          <div className="frame-1321314153">
            <div className="help-desk-avit-support-reimagined montserrat-bold-steel-gray-36px">
              <span className="montserrat-bold-steel-gray-36px">{spanText1}</span>
            </div>
            <div className="with-live-video-help-desk-support montserrat-semi-bold-steel-gray-24px">
              <span className="montserrat-semi-bold-steel-gray-24px">{spanText2}</span>
            </div>
            <div className="no-matter-how-many-r montserrat-normal-fiord-18px">
              <span className="montserrat-normal-fiord-18px">{spanText3}</span>
            </div>
          </div>
          <div className="overlap-group-89">
            <img className="qr-3" src={qr} />
            <img className="frame-1359971-6" src={frame1359971} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default X5Page7;
