import React from "react";
import "./Hardware.sass";

function Hardware(props) {
  const { className } = props;

  return (
    <div className={`hardware-2 ${className || ""}`}>
      <div className="flex-col-7">
        <div className="switch-2"></div>
        <div className="volume-up-button-2"></div>
        <div className="volume-down-button-2"></div>
      </div>
      <div className="notch-4">
        <div className="speaker-2"></div>
        <img className="camera-2" src="/img/camera-4@2x.png" />
      </div>
      <div className="power-button-2"></div>
    </div>
  );
}

export default Hardware;
