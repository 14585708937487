
const button4Data = {
    className: "",
};

const frame13213140624Data = {
    className: "frame-1321314062-3",
    buttonProps: button4Data,
};

const frame132131393621Data = {
    frame1321314062Props: frame13213140624Data,
};

const frame13213139181Data = {
    spanText1: "Saying hello to future, starts with saying goodbye to the past",
    spanText2: "So say goodbye to bulky intercom hardware. Say goodbye to visitor wait times. Say goodbye to an unproductive workforce. With QR Video Intercom, simply place your QR Code anywhere for unparalleled communication and support.",
};

const frame13213139782Data = {
    screenshot20220225At16461: "/img/qrcode.png",
    className: "frame-1321313977-1",
};



export const x2Page2Data = {
    x2Page_2: "/img/2-page-2-1@2x.png",
    qr: "/img/qr-1@2x.png",
    frame13599791: "/img/object-1@2x.png",
    frame13599792: "/img/object-2@2x.png",
    frame13599793: "/img/mockup-1@2x.png",
    frame13213139362Props: frame132131393621Data,
    frame1321313918Props: frame13213139181Data,
    frame1321313978Props: frame13213139782Data,
};