import React from "react";
import "./Burger.sass";

function Burger(props) {
  const {
    burger,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    spanText9,
    spanText10,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    codiconChromeClose,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="burger screen">
        <div className="frame-1321314022-5">
          <div className="frame-1321314018-5">
            <div className="place-5 valign-text-middle montserrat-semi-bold-steel-gray-18px">
              <span>
                <span className="montserrat-semi-bold-steel-gray-18px">{spanText1}</span>
              </span>
            </div>
            <div className="frame-1321314017-5 montserrat-normal-steel-gray-18px">
              <div className="technologies-5 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText2}</span>
                </span>
              </div>
              <div className="awards-5 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText3}</span>
                </span>
              </div>
              <div className="about-us-5 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText4}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="frame-1321314020-5">
            <div className="intercom-5 valign-text-middle montserrat-semi-bold-steel-gray-18px">
              <span>
                <span className="montserrat-semi-bold-steel-gray-18px">{spanText5}</span>
              </span>
            </div>
            <div className="frame-1321314019-10 montserrat-normal-steel-gray-18px">
              <div className="qr-video-intercom-6 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText6}</span>
                </span>
              </div>
              <div className="outdoor-display-7 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText7}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="frame-1321314022-item-1 valign-text-middle montserrat-semi-bold-steel-gray-18px">
            <span>
              <span className="montserrat-semi-bold-steel-gray-18px">{spanText8}</span>
            </span>
          </div>
          <div className="frame-1321314022-item-1 valign-text-middle montserrat-semi-bold-steel-gray-18px">
            <span>
              <span className="montserrat-semi-bold-steel-gray-18px">{spanText9}</span>
            </span>
          </div>
          <div className="frame-1321314021-5">
            <div className="surname-6 valign-text-middle montserrat-semi-bold-steel-gray-18px">
              <span>
                <span className="montserrat-semi-bold-steel-gray-18px">{spanText10}</span>
              </span>
            </div>
            <div className="frame-1321314019-11 montserrat-normal-steel-gray-18px">
              <div className="facilities-5 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText11}</span>
                </span>
              </div>
              <div className="hospitals-5 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText12}</span>
                </span>
              </div>
              <div className="transport-5 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText13}</span>
                </span>
              </div>
              <div className="higher-ed-5 valign-text-middle">
                <span>
                  <span className="montserrat-normal-steel-gray-18px">{spanText14}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <img className="codiconchrome-close" src={codiconChromeClose} />
      </div>
    </div>
  );
}

export default Burger;
