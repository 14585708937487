import React from "react";
import Frame1321314062 from "../Frame1321314062";
import "./Frame13213139364.sass";

function Frame13213139364(props) {
  const { className, frame1321314062Props } = props;

  return (
    <div className={`frame-1321313936-8 ${className || ""}`}>
      <img className="frame-1321314061-17" src="/img/fill-24-18@2x.png" />
      <div className="frame-1359884-18">
        <div className="intercoms-20 montserrat-normal-french-gray-14px">
          <span className="span-104 montserrat-normal-french-gray-14px">Intercoms</span>
        </div>
        <div className="video-concierge-29 montserrat-normal-french-gray-14px">
          <span className="span-104 montserrat-normal-french-gray-14px">Video Concierge</span>
        </div>
        <div className="help-as-a-service-29 montserrat-normal-fiord-14px">
          <span className="span-104 montserrat-normal-fiord-14px">Help As A Service</span>
        </div>
        <div className="contact-us-18 montserrat-normal-fiord-14px">
          <span className="span-104 montserrat-normal-fiord-14px">Contact Us</span>
        </div>
      </div>
      <Frame1321314062 className={frame1321314062Props.className} buttonProps={frame1321314062Props.buttonProps} />
    </div>
  );
}

export default Frame13213139364;
