import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import appendProps from '../../helpers/appendProps';
import { scrollToId } from '../../helpers/interceptAnchorScroll';
import Image from '../Image';
import './Card.sass'

const defaultLabel = 'More >'

export const cardBackgroundColors = {
    none: 'none',
    white: 'white',
}

const Card = (props) => {
    const {
        className,
        style,
        onClick,

        bgColor,
        bgOpacity,

        title,
        subtitle,
        text,
        callToAction,
        attachment,

        background,

        children
    } = props
    const hasContent = title || subtitle || text || attachment

    const hasBackgroundColor = Boolean(bgColor) && bgColor !== cardBackgroundColors.none 
    const isInteractive = (onClick || callToAction)
    const handleClick = (e) => {
        e.stopPropagation()
        if (onClick) onClick()
        if (callToAction) {
            if (callToAction.link[0] === '#') {
                scrollToId(callToAction.link)
            } else {
                window.location.assign(callToAction.link)
            }

        }
    }

    return <div
            className={classNames('card', className, hasBackgroundColor && `card-bg-${bgColor}`, isInteractive && 'interactive')}
            style={style}
            onClick={ isInteractive && handleClick}
        >
        { hasContent &&
            <div className='content'>
                {title && <h2
                        className='title'
                    >
                        {title}
                    </h2>}
                {subtitle && <h3 className='subtitle'>{subtitle}</h3>}
                {text && <div className='text'>{text}</div>}
                {
                callToAction && <div className='linksection'>
                    {
                            <a href={callToAction.link}>{callToAction.label || defaultLabel}</a>
                    }
                    </div>}
                { (attachment && isInteractive) ? appendProps(attachment, { onClick: handleClick, className: 'interactive' }) : attachment }
            </div>
        }
        {
            background && <Image className='background' src={background}/>
        }
        {children}
    </div>
}

Card.defaultProps = {
    bgColor: cardBackgroundColors.white
}

export default Card