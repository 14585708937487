import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import "./Button.sass";

//Dunno why Anima generates style names based on foreground, not background color 
// const colorVariants = {
//   white: 'montserrat-medium-blueberry-16px',
//   blue: 'montserrat-medium-white-16px',
//   gray: 'montserrat-normal-regent-gray-14px'
// }

const Button = (props) => {
  const {
    image, 
    className,
    titleClassName,
    title,
    onClick,
    href,
    color
  } = props;

  const handleClick = () => {
    onClick()
    if (href) window.location.assign(href)
  }
  return (
    <div
      className={classNames('component-button', color, className)}
      onClick={handleClick}
    >
        <span className={image && 'component-button-with-icon'}>
          {
            (image && typeof image === 'string') && <img src={image} className='component-button-icon'/>
          }
          {
            (image && typeof image !== 'string') && <div className='component-button-nav'>{image}</div> 
          }
          <span className={classNames("component-button-title", titleClassName)}>{title}</span>
        </span>
    </div>
  );
}

Button.defaultProps = {
  title: 'button',
  onClick: () => {},
  color: 'white'
}
export default Button;
