import React from "react";
import Frame1321313913 from "../Frame1321313913";
import Frame1321313914 from "../Frame1321313914";
import Frame13213139152 from "../Frame13213139152";
import "./Frame1321313915.sass";

function Frame1321313915(props) {
  const { className, frame1321313913Props, frame1321313914Props, frame13213139152Props } = props;

  return (
    <div className={`frame-132131391-2 ${className || ""}`}>
      <Frame1321313913 group={frame1321313913Props.group} />
      <Frame1321313914 group={frame1321313914Props.group} />
      <Frame13213139152 group={frame13213139152Props.group} />
    </div>
  );
}

export default Frame1321313915;
