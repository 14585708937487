import React from "react";
import "./RectangleButton5.sass";

function RectangleButton5(props) {
  const { rectangleButton, fontistoKey, className } = props;

  return (
    <div className={`rectangle-button-17 ${className || ""}`} style={{ backgroundImage: `url(${rectangleButton})` }}>
      <img className="fontistokey-2" src={fontistoKey} />
    </div>
  );
}

export default RectangleButton5;
