import React, { useState } from 'react';
import assets from '../../const/assets';
import { fullPathOrder } from '../../const/paths';
import CrossIcon from '../Icons/CrossIcon';
import Modal from '../Modal';
import MultiColumnNavbar from '../MultiColumnNavbar';

import './BurgerButton'

const BurgerButton = (props) => {
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);

    const toggleMenu = () => setIsMenuOpen(prev => !prev);
    return <>
        <img
            src={assets.burger}
            onClick={toggleMenu}
            className='burger-button'
        />
        {
            isMenuOpen && <Modal>
                <div className='modal-overlay'>
                    <CrossIcon
                        className='modal-close-button'
                        onClick={()=>{
                            setIsMenuOpen(false)
                        }}
                    />
                    <MultiColumnNavbar
                        links={fullPathOrder}
                        columnClassName={'montserrat-normal-regent-gray-18px'}
                        className={'burger-menu'}
                    />
                </div>
            </Modal>
        }
    </>
}

export default BurgerButton