import React from 'react';


const button33Data = {
    className: "",
};

const hardware22Data = {
    notchShape: "/img/notch-shape-19@2x.png",
};

const hardware23Data = {
    notchShape: "/img/notch-shape-20@2x.png",
    className: "hardware-19",
};

const frame132131406217Data = {
    className: "frame-1321314062-16",
    buttonProps: button33Data,
};

const frame132131393626Data = {
    className: "frame-1321313936-5",
    frame1321314062Props: frame132131406217Data,
};


export const x2Page1Data = {
    x2Page_1: "/img/2-page-1-1@2x.png",
    spanText1: "FONECOM",
    spanText2: "Introducing QR Video Intercom",
    spanText3: "A new world of connection is just a scan away",
    group82: "/img/group-82-5@2x.png",
    frame1: "/img/frame-19@2x.png",
    spanText4: "9:41",
    cellularConnection: "/img/cellular-connection-13@2x.png",
    wifi: "/img/wifi-15@2x.png",
    battery: "/img/battery-15@2x.png",
    screenShine1: "/img/screen-shine-22@2x.png",
    screenShine2: "/img/screen-shine-22@2x.png",
    group1359749: "/img/group-1359749-4@1x.png",
    frame1359980: "/img/group-1359841-3@2x.png",
    group1359842: "/img/group-1359842-1@2x.png",
    shadows: "/img/shadows-16@2x.png",
    frame2: "/img/frame-18@2x.png",
    intercomMobileDoorOnly: "/img/intercom---mobile---door-only-12@1x.png",
    notch: "/img/notch-shape-18@2x.png",
    camera: "/img/camera-5@2x.png",
    frame3: "/img/frame-20@2x.png",
    frame119: "/img/header-2@2x.png",
    screenShine3: "/img/screen-shine-24@2x.png",
    screenShine4: "/img/screen-shine-24@2x.png",
    frame13213139362Props: frame132131393626Data,
    hardware21Props: hardware22Data,
    hardware22Props: hardware23Data,
};

const button7Data = {
    className: "",
};

const frame13213140626Data = {
    className: "frame-1321314062-5",
    buttonProps: button7Data,
};

const frame132131393623Data = {
    className: "frame-1321313936-2",
    frame1321314062Props: frame13213140626Data,
};

const hardware2Data = {
    className: "hardware-3",
};

const frame13213139783Data = {
    screenshot20220225At16461: "/img/screenshot-2022-02-25-at-16-51-1-1@2x.png",
    className: "frame-1321313978-1",
};

export const x2Page5Data = {
    x2Page_5: "/img/2-page-5-1@2x.png",
    image91: "/img/image-91-2@2x.png",
    google_MapsLogo1: "/img/google-maps-logo-1-1@2x.png",
    image92: "/img/image-92-1@2x.png",
    image93: "/img/image-93-1@2x.png",
    googleforms_LogoE15299213911531: "/img/googleforms-logo-e1529921391153-1-1@2x.png",
    adobe_Acrobat_Dc_Logo_20201: "/img/adobe-acrobat-dc-logo-2020-1-1@2x.png",
    spanText1: "Adobe PDF",
    servicenow_Logo1: "/img/servicenow-logo-1-1@2x.png",
    x1_Qaxizthcika5Bzumkcy2Q1: "/img/1--qaxizthcika5bzumkcy2q-1-1@2x.png",
    x6117A82537Bc3B7A02Bd5472_60A2746E0: "/img/6117a82537bc3b7a02bd5472-60a2746e0721c10af774eb79-microsoft-20fo-1@2x.png",
    shadows: "/img/shadows-7@2x.png",
    frame: "/img/frame-4@2x.png",
    intercomMobileDoorOnly: "/img/intercom---mobile---door-only-4@1x.png",
    screenShine: "/img/screen-shine-2@2x.png",
    spanText2: "Third party? Zero Problem",
    spanText3: "Designed to effortlessly connect with popular  products you use every day. You can now, quite literally, open the door and welcome people into a world of integrated access functionality.",
    spanText4: "List of Integrated partners >",
    frame13213139362Props: frame132131393623Data,
    hardwareProps: hardware2Data,
    frame1321313978Props: frame13213139783Data,
};


const button32Data = {
    className: "button-49",
};

const frame132131406216Data = {
    className: "frame-1321314062-15",
    buttonProps: button32Data,
};

const frame132131393625Data = {
    className: "frame-1321313936-4",
    frame1321314062Props: frame132131406216Data,
};

const frame13213139081Data = {
    spanText1: "For them",
    spanText2: "Visitors simply  scan the QR Video Intercom code (no app required)",
};

const bilockFill3Data = {
    src: "/img/group-87@2x.png",
    className: "bilock-fill-3",
};

const frame13213139082Data = {
    spanText1: "For you",
    spanText2: <>-   	You receive a PUSH notification - Phone/Tablet/PC<br />-   	You’ll see which QR entry has been scanned<br />-   	Answer the call with the click of a button<br />-   	Unlock  doors and elevators whilst on the call</>,
    className: "frame-1321313908-3",
};

const mobileMenu36Data = {
    fill24: "/img/fill-24-18@2x.png",
    ciHamburger: "/img/ci-hamburger-18@2x.png",
    className: "mobile-menu-35",
};

export const x2Page6Data = {
    x2Page_6: "/img/2-page-6-1@2x.png",
    spanText1: "How it works?",
    overlapGroup4: "/img/iphone-11-pro---x---7-1@1x.png",
    frame1359979: "/img/qr-code-1@2x.png",
    group1359765: "/img/group-1359754-1@2x.png",
    fill24: "/img/fill-24-36@2x.png",
    frame13599801: "/img/group-1359979-1@2x.png",
    group1359755: "/img/group-89-1@1x.png",
    wcVideoCallWithChat: "/img/wc-video-call-with-chat-1@2x.png",
    spanText2: "9:41",
    cellularConnection1: "/img/cellular-connection-11@2x.png",
    wifi1: "/img/wifi-13@2x.png",
    battery1: "/img/battery-13@2x.png",
    overlapGroup1: "/img/teleportivity-com-1@2x.png",
    vector2: "/img/vector-12@2x.png",
    group1359868: "/img/frame-1359980-1@2x.png",
    frame13599802: "/img/screens-11@1x.png",
    group1359866: "/img/group-1359866-9@2x.png",
    incomingCall: "/img/incoming-call-2@2x.png",
    frame: "/img/frame-17@2x.png",
    intercomMobileDoorOnly: "/img/intercom---mobile---door-only-11@1x.png",
    spanText3: "9:41",
    cellularConnection2: "/img/cellular-connection-12@2x.png",
    wifi2: "/img/wifi-14@2x.png",
    battery2: "/img/battery-14@2x.png",
    screenShine: "/img/screen-shine-20@2x.png",
    notch: "/img/notch-shape-17@2x.png",
    camera: "/img/camera-17@2x.png",
    x2Page_7: "/img/2-page-7@1x.png",
    x2Page_7_Mobile: "/img/2-page-7-mobile-1@2x.png",
    spanText4: <>Don’t just say hello <br />to visitors. Say hello <br />to the future of connection.</>,
    spanText5: "Calling QR Video Intercom just an intercom is like calling the iPhone just a phone. Sure, it can connect you in all the familiar ways. But it also integrates with popular third parties; creating smart spaces, smart buildings and smart points wherever you want them.",
    spanText6: "Navigation Panel",
    line311: "/img/line-31-1@2x.png",
    iphonexsSpaceGrayscreen1: "/img/iphonexs---space-grayscreen-1-1@2x.png",
    spanText7: <>Link to Google Maps<br />&amp; Google Directions</>,
    line312: "/img/line-31-2@2x.png",
    iphonexsSpaceGraynewfrtre1: "/img/iphonexs---space-graynewfrtre-1-1@2x.png",
    spanText8: "Optional QR Lock",
    line313: "/img/line-31-3@2x.png",
    iphonexsSpaceGraynewnew2Frtre1: "/img/iphonexs---space-graynewnew-2frtre-1-1@2x.png",
    spanText9: <>Pre-recorded videos<br />Youtube / MP4</>,
    line314: "/img/line-31-6@2x.png",
    iphonexsSpaceGraynew2: "/img/iphonexs---space-graynew-2-1@2x.png",
    spanText10: "Documents & Images",
    line315: "/img/line-31-5@2x.png",
    iphonexsSpaceGraynew8: "/img/iphonexs---space-graynew-8-1@1x.png",
    spanText11: "Intercom system",
    line316: "/img/line-31-6@2x.png",
    iphoneVer11: "/img/iphone---ver-1-1-1@2x.png",
    spanText12: "Multiple User Calling Groups",
    line317: "/img/line-31-7@2x.png",
    iphonexsSpaceGraynew1: "/img/iphonexs---space-graynew-1-1@2x.png",
    spanText13: "Call Queuing System",
    line318: "/img/line-31-8@2x.png",
    group401: "/img/group-40-1-1@2x.png",
    spanText14: "Unlock the door right from the call",
    line319: "/img/line-31-9@2x.png",
    spanText15: "Unlock the door from the app",
    line3110: "/img/line-31-11@2x.png",
    intercomMobile: "/img/intercom--mobile-1@1x.png",
    group1359750: "/img/group-1359750-1@2x.png",
    group1359749: "/img/intercom--mobile-2@1x.png",
    spanText16: <>Video call<br />Flip Camera / Transfer Call / Augmented Reality</>,
    line3111: "/img/line-31-10@2x.png",
    frame13213139362Props: frame132131393625Data,
    frame13213139081Props: frame13213139081Data,
    bilockFillProps: bilockFill3Data,
    frame13213139082Props: frame13213139082Data,
    mobileMenuProps: mobileMenu36Data,
};