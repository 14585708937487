import React from "react";
import Frame1359884 from "../Frame1359884";
import Frame1321314062 from "../Frame1321314062";
import Frame1321313911 from "../Frame1321313911";
import Frame1321313917 from "../Frame1321313917";
import Frame1321313915 from "../Frame1321313915";
import "./X5Page8.sass";

function X5Page8(props) {
  const {
    x5Page_8,
    frame1321314061,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    frame1359884Props,
    frame1321314062Props,
    frame1321313911Props,
    frame1321313917Props,
    frame1321313915Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x5-page8 screen">
        <div className="frame-1321314154">
          <div className="get-started-free-1 montserrat-bold-steel-gray-36px">
            <span className="montserrat-bold-steel-gray-36px">{spanText1}</span>
          </div>
          <div className="frame-1321313976-2">
            <div className="frame-1321314007-2">
              <Frame1321313911 className={frame1321313911Props.className} />
              <div className="my-qr-fonecom-3 montserrat-semi-bold-black-24px">
                <span className="montserrat-semi-bold-black-24px">{spanText2}</span>
              </div>
              <Frame1321313917
                group1={frame1321313917Props.group1}
                group2={frame1321313917Props.group2}
                className={frame1321313917Props.className}
                frame1321313913Props={frame1321313917Props.frame1321313913Props}
              />
              <div className="register-now-2 montserrat-semi-bold-blueberry-18px">
                <span className="montserrat-semi-bold-blueberry-18px">{spanText3}</span>
              </div>
            </div>
            <div className="frame-1321314010-2">
              <div className="help-as-a-service-45 montserrat-semi-bold-steel-gray-24px">
                <span className="montserrat-semi-bold-steel-gray-24px">{spanText4}</span>
              </div>
              <Frame1321313915
                className={frame1321313915Props.className}
                frame1321313913Props={frame1321313915Props.frame1321313913Props}
                frame1321313914Props={frame1321313915Props.frame1321313914Props}
                frame13213139152Props={frame1321313915Props.frame13213139152Props}
              />
              <div className="buy-now-8 montserrat-semi-bold-blueberry-18px">
                <span className="montserrat-semi-bold-blueberry-18px">{spanText5}</span>
              </div>
              <div className="try-for-30-days-4 montserrat-semi-bold-blueberry-18px">
                <span className="montserrat-semi-bold-blueberry-18px">{spanText6}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default X5Page8;
