import React from "react";
import "./Group1359838.sass";

function Group1359838(props) {
  const { className } = props;

  return (
    <div className={`group-1359838 ${className || ""}`}>
      <div className="the-way-we-communica-2 montserrat-normal-white-18px-2">
        <span className="span-36 montserrat-normal-white-18px-2">
          The way we communicate and run our businesses is evolving.
          <br />
          But the Intercom – that crucial first point of connection – isn’t .
        </span>
      </div>
      <div className="lets-change-that-2 montserrat-semi-bold-white-18px">
        <span className="span-36 montserrat-semi-bold-white-18px">Let’s change that.</span>
      </div>
    </div>
  );
}

export default Group1359838;
