import React from 'react';


const button6Data = {
    className: "button-23",
};

const frame13213140625Data = {
    className: "frame-1321314062-4",
    buttonProps: button6Data,
};

const frame132131393622Data = {
    className: "frame-1359970-1",
    frame1321314062Props: frame13213140625Data,
};

const frame13213139182Data = {
    spanText1: "Ensure it’s the packages that drop off, not the productivity.",
    spanText2: "Large-scale loading docks, distribution centers and delivery areas can be complex and overwhelming. But with QR VIDEO INTERCOM, even the biggest facilities can be managed with the smallest teams.",
    className: "frame-1321313896-1",
};

export const x2Page3Data = {
    x2Page_3: "/img/2-page-3-1@2x.png",
    frame1359979: "/img/shadows-6@2x.png",
    frame: "/img/frame-4@2x.png",
    intercomMobileDoorOnly: "/img/intercom---mobile---door-only-3@1x.png",
    screenShine: "/img/screen-shine-2@2x.png",
    screenshot20220225At16441: "/img/screenshot-2022-02-25-at-16-44-1-1@2x.png",
    spanText: "Scan the code to see how QR Video Intercom can reimagine your warehouse and logistics",
    frame13213139362Props: frame132131393622Data,
    frame1321313918Props: frame13213139182Data,
};