import React from "react";
import { qrideoPaths } from "../../const/paths";
import "./Footer.sass";

function Footer(props) {
  const { className } = props;

  return (
    <div className={`footer-1 ${className || ""}`}>
      <div className="frame-1321314022-1">
        <div className="frame-1321314018-1">
          <div className="place-1 valign-text-middle montserrat-semi-bold-regent-gray-18px">
            <span>
              <span className="span-6 montserrat-semi-bold-regent-gray-18px">Home</span>
            </span>
          </div>
          <div className="frame-1321314017-1 montserrat-normal-regent-gray-18px">
            <div className="technologies-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">Technologies</span>
              </span>
            </div>
            <div className="awards-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">Awards</span>
              </span>
            </div>
            <div className="about-us-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">About us</span>
              </span>
            </div>
          </div>
        </div>
        <div className="frame-1321314020-1">
          <div className="intercom-1 valign-text-middle montserrat-semi-bold-regent-gray-18px">
            <span>
              <span className="span-6 montserrat-semi-bold-regent-gray-18px">Intercom</span>
            </span>
          </div>
          <div className="frame-1321314019-2 montserrat-normal-regent-gray-18px">
            <div className="qr-video-intercom-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">QR Video Intercom</span>
              </span>
            </div>
            <div className="outdoor-display-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">Outdoor Display</span>
              </span>
            </div>
          </div>
        </div>
        <div className="video-concierge-4 valign-text-middle montserrat-semi-bold-regent-gray-18px">
          <span>
            <span className="span-6 montserrat-semi-bold-regent-gray-18px">Video Concierge</span>
          </span>
        </div>
        <div className="help-as-a-service-3 valign-text-middle montserrat-semi-bold-regent-gray-18px">
          <span>
            <span className="span-6 montserrat-semi-bold-regent-gray-18px">Help As A Service</span>
          </span>
        </div>
        <div className="frame-1321314021-1">
          <div className="surname-2 valign-text-middle montserrat-semi-bold-regent-gray-18px">
            <span>
              <span className="span-6 montserrat-semi-bold-regent-gray-18px">Case&nbsp;&nbsp;Studies</span>
            </span>
          </div>
          <div className="frame-1321314019-3 montserrat-normal-regent-gray-18px">
            <div className="facilities-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">Facilities</span>
              </span>
            </div>
            <div className="hospitals-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">Hospitals</span>
              </span>
            </div>
            <div className="transport-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">Transport</span>
              </span>
            </div>
            <div className="higher-ed-1 valign-text-middle">
              <span>
                <span className="span-6 montserrat-normal-regent-gray-18px">Higher Ed.</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="group-1359927-1">
        <img className="footer-1-1" src="/img/footer-qr.png" />
        <p className="scan-to-talk-to-us-live-or-click-here-1 montserrat-normal-regent-gray-14px">
          <span className="span-6 montserrat-normal-regent-gray-14px">
            SCAN to talk to us <br />
            live! Or click{" "}
          </span>
          <span className="span-6 montserrat-medium-royal-blue-14px">here</span>
        </p>
      </div>
      <div className="group-1359871 montserrat-normal-regent-gray-14px">
        <p className="httpsqrideomeco">
          <span className="span-6 montserrat-normal-regent-gray-14px">
            {qrideoPaths.contact}
            <br />
            <br />
            USA Patent 10,873,723
            <br />
            USA Patent 11,089,263
          </span>
        </p>
        <div className="flex-col-2 montserrat-normal-regent-gray-14px">
          <div className="privacy-policy">
            <span className="span-6 montserrat-normal-regent-gray-14px">Privacy Policy</span>
          </div>
          <div className="terms-and-conditions">
            <span className="span-6 montserrat-normal-regent-gray-14px">Terms and Conditions</span>
          </div>
        </div>
        <p className="website-by-inventri">
          <span className="span-6 montserrat-normal-regent-gray-14px">
            Website by: Inventrix Technologies Pty Ltd <br />© Teleportivity Inc. 2022
            <br />
            US Office: 1390 Market Street, San Francisco, CA, 94102, USA
            <br />
            AU Office: Suite 201-202, 413-417 New South Head Rd, Double Bay NSW 2028 AUSTRALIA
          </span>
        </p>
      </div>
    </div>
  );
}

export default Footer;
