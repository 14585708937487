import React from "react";
import "./Frame13598842.sass";

function Frame13598842() {
  return (
    <div className="frame-1359884-2">
      <div className="intercoms-4 montserrat-normal-fiord-14px">
        <span className="montserrat-normal-fiord-14px">Intercoms</span>
      </div>
      <div className="video-concierge-8 montserrat-normal-french-gray-14px">
        <span className="montserrat-normal-french-gray-14px">Video Concierge</span>
      </div>
      <div className="help-as-a-service-7 montserrat-normal-french-gray-14px">
        <span className="montserrat-normal-french-gray-14px">Help As A Service</span>
      </div>
      <div className="contact-us-2 montserrat-normal-french-gray-14px">
        <span className="montserrat-normal-french-gray-14px">Contact Us</span>
      </div>
    </div>
  );
}

export default Frame13598842;
