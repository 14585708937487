import React from "react";
import Button from "../Button";
import "./Frame1321314062.sass";

function Frame1321314062(props) {
  const { className, buttonProps } = props;

  return (
    <div className={`frame-1321314062 ${className || ""}`}>
      <Button className={buttonProps.className} />
      <div className="button-3">
        <div className="feedbacks-3 valign-text-middle montserrat-medium-white-16px">
          <span>
            <span className="spandlqwv montserrat-medium-white-16px">Get Started</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Frame1321314062;
