export const plans = {
    free: {
        name: 'My Qr Fonecom',
        free: true,
        text: true,
        voice: 10,
        video: 10,
        access: false
    },
    home: {
        name: 'Fonecom Home',
        text: true,
        voice: true,
        video: true,
        access: false
    },
    office: {
        name: 'Fonecom Office',
        text: true,
        voice: true,
        video: true,
        access: false        
    },
    enterprise: {
        name: 'Fonecom Enterprise',
        text: true,
        voice: true,
        video: true,
        access: false
    },
    apartment: {
        name: 'Fonecom Apartment',
        text: true,
        voice: true,
        video: true,
        access: false
    }
}

export const planOptionNames = {
    text: 'text chat',
    voice: 'voice',
    video: 'video',
    access: 'access control'

}