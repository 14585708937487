import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import {paths} from "../../../const/paths";
import './NavMenu.sass'

const NavElement = (props) => {
  const { 
    path,
    alias,
    isActive,
    className
  } = props;

  return <a href={path}>
    <div className={classNames('component-navmenu-item', className)}>
      <span className={classNames("montserrat-normal-french-gray-14px", isActive && "montserrat-normal-fiord-14px")}>{alias}</span>
    </div>
    </a>
}

const NavMenu = (props) => {
    const { currentPath } = props;
      return <div className="component-navmenu"> {
        Object.entries(paths)
          .map(([path, link]) => 
        link.main && <NavElement
          path={link.href || `/${path}`}
          alias={link.title}
          className={link.main && 'mainmenu'}
          key={path}
          isActive={currentPath === `/${path}`}
        />
      )
    }
  </div>
}

export default NavMenu;